import dojo_on = require("dojo/on");

import { Compare as Cmp, Str } from "core";
import { Icon as ReactIcon, InlineBanner } from "design-system";
import * as Base from "Everlaw/Base";
import * as BillingUtil from "Everlaw/BillingUtil";
import * as DateUtil from "Everlaw/DateUtil";
import * as Dom from "Everlaw/Dom";
import * as Input from "Everlaw/Input";
import Dataset from "Everlaw/Model/Processing/ProcessingDataset";
import {
    chatSegmentationDisplay,
    ChatSegmentationOption,
    imageProxyDisplay,
    OCR_AUTODETECT_ID,
    OCR_AUTODETECT_NAME,
    OCR_LANGUAGES,
    PageSizeOption,
    PdfOption,
    pdfOptionDisplay,
    slackAttachmentsDisplay,
} from "Everlaw/Model/Processing/ProcessingDefs";
import ProcessingPrivateKey from "Everlaw/Model/Processing/ProcessingPrivateKey";
import * as Project from "Everlaw/Project";
import * as Server from "Everlaw/Server";
import * as TimezonesCommon from "Everlaw/TimezonesCommon";
import * as UI from "Everlaw/UI";
import * as BasicRadio from "Everlaw/UI/BasicRadio";
import * as Checkbox from "Everlaw/UI/Checkbox";
import * as FocusDiv from "Everlaw/UI/FocusDiv";
import * as Icon from "Everlaw/UI/Icon";
import { wrapReactComponent } from "Everlaw/UI/ReactWidget";
import * as SingleSelect from "Everlaw/UI/SingleSelect";
import * as TextBox from "Everlaw/UI/TextBox";
import * as Validated from "Everlaw/UI/Validated";
import * as Util from "Everlaw/Util";
import * as moment from "moment-timezone";
import { createElement } from "react";

/**
 * This interface is used for the various configuration widgets we add to the different
 * configurators.
 *
 * It contains {@link ConfigBlock}-like fields, so that these can easily be used as config blocks.
 */
export interface ConfigWidget {
    title: Dom.Content;
    /**
     * Learn-more link.
     */
    learnMore?: Dom.Content;
    /**
     * Help tooltip.
     */
    help?: Dom.Content;
    /**
     * Callback for which this widget changes.
     */
    onChange: () => void;

    getNode(): HTMLElement;

    /**
     * Get the current widget value.
     */
    getValue(): any;

    /**
     * Reset this widget, optionally using the given dataset as our source for the default value.
     */
    reset(dataset?: Dataset): void;
}

interface Summary {
    title?: string;
    value: string;
}

/**
 * When we're building widgets to put in the advanced section, we also want to be able to generate a
 * summary when the advanced section is closed.
 */
export interface AdvancedConfigWidget extends ConfigWidget {
    getSummary(): Summary;
}

export class CheckboxWidget implements AdvancedConfigWidget {
    readonly title: string;
    onChange: () => void;

    private readonly checkbox: Checkbox;

    constructor(
        private label: string,
        private initial = false,
    ) {
        this.checkbox = new Checkbox({
            label: this.label,
            onChange: () => this.onChange && this.onChange(),
        });
        this.title = label;
    }

    getNode(): HTMLElement {
        return Dom.node(this.checkbox);
    }

    getValue(): boolean {
        return !!this.checkbox.isSet();
    }

    reset(): void {
        this.checkbox.set(this.initial, true);
    }

    getSummary(): Summary {
        return { value: this.getValue() ? this.label : "" };
    }

    setState(state: boolean, silent?: boolean): void {
        this.checkbox.set(state, silent);
    }

    setDisabled(disabled: boolean): void {
        this.checkbox.setDisabled(disabled);
    }
}

export class CheckboxWithInfo extends CheckboxWidget {
    private readonly wrapperDiv: HTMLElement;

    constructor(label: string, info: Dom.Content) {
        super(label);
        this.wrapperDiv = Dom.div(
            { class: "h-spaced-4" },
            super.getNode(),
            Dom.node(
                new Icon("info-circle-20", {
                    tooltip: info,
                }),
            ),
        );
    }

    override getNode(): HTMLElement {
        return this.wrapperDiv;
    }
}

enum ImageProxySetting {
    USE_IMAGEPROXY = "USE_IMAGEPROXY",
    NO_IMAGEPROXY = "NO_IMAGEPROXY",
    DATASET_DEFAULT = "DATASET_DEFAULT",
}

export class ImageProxyWidget implements AdvancedConfigWidget {
    readonly title: string = "Hyperlinked images";
    readonly help: string =
        "Images referenced by URL (e.g. in emails or HTML) can optionally be fetched and displayed "
        + "when rendering to PDF";
    onChange: () => void;

    private readonly radio: BasicRadio;
    private readonly useImageProxySubcontent: HTMLElement = Dom.div(
        "Images referenced by URL (e.g. in emails or HTML) will be fetched and displayed when "
            + "rendering to PDF",
    );
    private readonly noImageProxySubcontent: HTMLElement = Dom.div(
        "Images referenced by URL will not be fetched and displayed",
    );
    private readonly warningIcon: HTMLElement = Icon.callout(
        "alert-triangle-20",
        UI.alignedContainer({
            content: Dom.div(
                "Hyperlinked images will be collected at the time of processing. ",
                "Everlaw cannot guarantee and makes no representation as to whether collected ",
                "images match content available at their associated URLs at any prior date.",
            ),
            vertical: true,
        }),
        false,
    );
    private readonly warning: HTMLElement = Dom.div(
        { style: { paddingTop: "8px" } },
        this.warningIcon,
    );

    constructor(includeDatasetOption: boolean) {
        const options: BasicRadio.Element[] = [
            {
                id: ImageProxySetting.NO_IMAGEPROXY,
                display: imageProxyDisplay(false),
                subcontent: this.noImageProxySubcontent,
            },
        ];
        // If the server supports proxying images, then show this option. If not, we should default
        // to not using it (and it's likely that the configuration widget won't be displayed in any
        // case!).
        if (Server.isImageProxyEnabled()) {
            options.unshift({
                id: ImageProxySetting.USE_IMAGEPROXY,
                display: imageProxyDisplay(true),
                subcontent: Dom.div(Dom.div(this.useImageProxySubcontent), this.warning),
            });
        }
        if (includeDatasetOption) {
            options.unshift({
                id: ImageProxySetting.DATASET_DEFAULT,
                display: "Dataset default",
            });
        }
        this.radio = new BasicRadio(options);
        this.radio.select(this.radio.elements[0]);
        const onOptionChange = (id: string) => {
            Dom.show(this.warning, id === ImageProxySetting.USE_IMAGEPROXY);
            this.onChange && this.onChange();
        };
        onOptionChange(this.radio.getSelectedId());
        this.radio.onChange = (changed) => onOptionChange(changed.id);
    }

    showSubcontent(show: boolean): void {
        Dom.show([this.useImageProxySubcontent, this.noImageProxySubcontent], show);
        Dom.style(this.warning, "paddingTop", show ? "8px" : "0");
    }

    getNode(): HTMLElement {
        return this.radio.getNode();
    }

    getValue(): boolean | null {
        const val = this.radio.getSelectedId();
        if (val === ImageProxySetting.USE_IMAGEPROXY) {
            return true;
        } else if (val === ImageProxySetting.NO_IMAGEPROXY) {
            return false;
        }
        return null;
    }

    reset(ds?: Dataset): void {
        if (ds) {
            this.radio.select(
                ds.config.use_image_proxy && Server.isImageProxyEnabled()
                    ? ImageProxySetting.USE_IMAGEPROXY
                    : ImageProxySetting.NO_IMAGEPROXY,
                false,
            );
        } else {
            this.radio.select(this.radio.elements[0], false);
        }
        Dom.show(this.warning, this.radio.getSelectedId() === ImageProxySetting.USE_IMAGEPROXY);
    }

    getSummary(): Summary {
        return {
            title: this.title,
            value: this.description(ImageProxySetting[this.radio.getSelectedId()]),
        };
    }

    private description(setting: ImageProxySetting): string {
        if (setting === ImageProxySetting.DATASET_DEFAULT) {
            return "Dataset default";
        }
        return imageProxyDisplay(setting === ImageProxySetting.USE_IMAGEPROXY);
    }
}

export function keyHelp(): HTMLElement[] {
    return [
        Dom.div(
            "Decryption keys for S/MIME emails (and other encrypted formats) can be uploaded and ",
            "administered on the project upload page under Advanced Settings. ",
            "Keys can be uploaded in PFX (PKCS#12) or PEM (PKCS1/PCKS8) formats. ",
            "The uploaded keys are used for decryption across all datasets.",
        ),
        Dom.a(
            {
                href: "https://support.everlaw.com/hc/en-us/articles/360021968851?flash_digest=cd7c3ca081b85cd89d1d4471e87aafb931e207c2#h_b6245f67-47dd-4199-abf4-5a02364bfab9",
                rel: "noopener noreferrer",
                target: "_blank",
                class: "reprocess-dialog__decryption-key-learn-more",
            },
            "Learn more",
        ),
    ];
}

export class KeyWidget implements AdvancedConfigWidget {
    readonly title: Dom.Content;
    readonly help: Dom.Content = keyHelp();
    readonly onChange: () => void;

    private readonly node: HTMLElement;

    constructor(title: Dom.Content[] = ["Decryption Keys"]) {
        const count = Base.get(ProcessingPrivateKey).length;
        const countStr = count > 0 ? ` (${count})` : "";
        this.node = Dom.a(
            {
                href: Project.CURRENT.urlFor("data", { tab: "advanced-settings" }),
                target: "_blank",
                class: "everblue-link",
            },
            `Manage decryption keys${countStr}`,
        );
        const focusDiv = FocusDiv.makeFocusable(this.node, "focus-text-style");
        dojo_on(this.node, "click", () => {
            ga_event("Processing", "Follow key info link");
        });
        focusDiv.registerDestroyable(
            Input.fireCallbackOnKey(focusDiv.node, [Input.ENTER], () => this.node.click()),
        );
        this.title = title;
    }

    getNode(): HTMLElement {
        return this.node;
    }

    getValue(): null {
        return null;
    }

    reset(): void {}

    getSummary(): null {
        return null;
    }
}

export class NameWidget implements ConfigWidget {
    readonly title: string = "Name";
    onChange: () => void;

    private textbox = new TextBox({
        placeholder: "Give your dataset a unique name",
    });

    constructor() {
        this.textbox.onChange = () => this.onChange && this.onChange();
    }

    getNode(): HTMLElement {
        return this.textbox.getNode();
    }

    getValue(): string {
        return this.textbox.getValue();
    }

    reset(): void {
        this.textbox.clear();
    }
}

export class PasswordWidget implements ConfigWidget {
    readonly title: string = "Passwords for protected files";
    onChange: () => void;

    private textArea = Dom.textarea({
        placeholder: "Type passwords here, one per line",
        style: {
            resize: "none",
            height: "72px",
        },
    });

    constructor() {
        Dom.setAriaLabel(
            this.textArea,
            "Password for protected files, Type passwords here, one per line",
        );
    }

    getNode(): HTMLElement {
        return this.textArea;
    }

    getValue(): string[] {
        return this.textArea.value.split("\n").filter((pw) => !!pw);
    }

    reset(): void {
        this.textArea.value = "";
        this.textArea.onchange = () => this.onChange && this.onChange();
    }
}

export class PdfOptionWidget implements AdvancedConfigWidget {
    readonly billingMessageDiv = Dom.div();

    readonly title: Dom.Content = ["Create PDFs for", this.billingMessageDiv];
    onChange: () => void;

    private readonly radio: BasicRadio;
    private readonly makePlaceholders = new Checkbox({
        label: "Do not image spreadsheets and large text files (>5 MB) that may not convert well to PDF",
        state: true,
        onChange: () => this.onChange && this.onChange(),
    });

    constructor(private includeDatasetOption: boolean) {
        const pdfOptions = [
            {
                id: PdfOption.ALL,
                display: "All documents",
                subcontent: Dom.node(this.makePlaceholders),
                bottomMargin: "8px",
            },
            {
                id: PdfOption.NONE,
                display: "No documents",
            },
        ];
        if (includeDatasetOption) {
            pdfOptions.unshift({
                id: PdfOption.DATASET,
                display: "Dataset default",
            });
        }
        this.radio = new BasicRadio(pdfOptions);
        this.radio.select(this.radio.elements[0]);
        const onPdfOptionChange = (id: string) => {
            const settings = this.getPdfOptionSettings(PdfOption[id]);
            Dom.show(this.makePlaceholders, settings.showPlaceholders);
            this.onChange && this.onChange();
        };
        onPdfOptionChange(this.radio.getSelectedId());
        this.radio.onChange = (changed) => onPdfOptionChange(changed.id);
        if (Project.CURRENT && !BillingUtil.totalBilling(Project.CURRENT.billingMode)) {
            Dom.create(
                "div",
                {
                    class: "setting-description",
                    content: "Creating PDFs will not affect your billable size",
                },
                this.billingMessageDiv,
            );
        }
    }

    getNode(): HTMLElement {
        return this.radio.getNode();
    }

    getValue(): PdfOption | null {
        return this.getSelectedOption();
    }

    reset(ds?: Dataset): void {
        let pdfOpt: PdfOption;
        if (ds) {
            pdfOpt = PdfOption[ds.config.pdfs];
        } else if (this.includeDatasetOption) {
            pdfOpt = PdfOption.DATASET;
        }
        const pdfSettings = this.getPdfOptionSettings(pdfOpt);
        this.radio.select(pdfSettings.selectorId, true);
        this.makePlaceholders.set(pdfSettings.placeholders, true);
        Dom.show(this.makePlaceholders, pdfSettings.showPlaceholders);
    }

    getSummary(): Summary {
        return { title: "PDFs", value: pdfOptionDisplay(this.getSelectedOption()) };
    }

    private getSelectedOption(): PdfOption | null {
        let pdfs = PdfOption[this.radio.getSelectedId()];
        if (pdfs === PdfOption.DATASET) {
            // Dataset default pdfs.
            pdfs = null;
        } else if (pdfs === PdfOption.ALL && this.makePlaceholders.isSet()) {
            pdfs = PdfOption.DEFAULT;
        }
        return pdfs;
    }

    private getPdfOptionSettings(pdfOption: PdfOption): {
        placeholders: boolean;
        selectorId: PdfOption;
        showPlaceholders: boolean;
    } {
        let placeholders: boolean;
        let selectorId: PdfOption;
        let showPlaceholders: boolean;
        switch (pdfOption) {
            case PdfOption.DATASET:
                placeholders = true;
                showPlaceholders = false;
                selectorId = PdfOption.DATASET;
                break;
            case PdfOption.ALL:
                placeholders = false;
                showPlaceholders = true;
                selectorId = PdfOption.ALL;
                break;
            case PdfOption.NONE:
                placeholders = true;
                showPlaceholders = false;
                selectorId = PdfOption.NONE;
                break;
            default:
                // Handles DEFAULT as well as the fallthrough.
                placeholders = true;
                showPlaceholders = true;
                selectorId = PdfOption.ALL;
                break;
        }
        return { placeholders, selectorId, showPlaceholders };
    }
}

type RadioWidgetParams = {
    title: Dom.Content;
    summaryTitle?: string;
    options: BasicRadio.Element[];
    display?: (radioElementId: string) => string;
    /**
     * Restores the widget value from an existing dataset.
     */
    datasetValue?: (dataset: Dataset) => string;
    help?: Dom.Content;
    separateLines?: boolean;
    /**
     * An optional element we want to add to this selector to indicate a "dataset default" fallback
     * element. If provided, this element will be added, sorted to the top, and, if selected, will
     * generate a `null` value.
     */
    datasetElement?: BasicRadio.Element;
    learnMore?: HTMLElement;
};

abstract class RadioWidget<V> implements AdvancedConfigWidget {
    readonly title: Dom.Content;
    readonly learnMore?: Dom.Content;
    readonly help?: Dom.Content;
    onChange: () => void;

    private readonly summaryTitle?: string;
    protected readonly radio: BasicRadio;
    private readonly display: (id: string) => string;
    private readonly datasetValue?: (ds: Dataset) => string;
    protected readonly datasetElement?: BasicRadio.Element;

    constructor({
        title,
        summaryTitle,
        options,
        display = (id: string): string => id,
        datasetValue,
        help,
        separateLines = true,
        datasetElement,
        learnMore,
    }: RadioWidgetParams) {
        this.title = title;
        this.learnMore = learnMore;
        this.help = help;
        this.summaryTitle = summaryTitle;
        this.display = display;
        this.datasetValue = datasetValue;
        this.datasetElement = datasetElement;

        const radioOptions = datasetElement ? [datasetElement].concat(options) : options;
        this.radio = new BasicRadio(radioOptions, separateLines);
        this.radio.select(this.radio.elements[0]);
        this.radio.onChange = () => this.onChange && this.onChange();
    }

    getNode(): HTMLElement {
        return this.radio.getNode();
    }

    abstract getValue(): V | null;

    reset(ds?: Dataset): void {
        if (ds && this.datasetValue) {
            this.radio.select(this.datasetValue(ds), false);
        } else {
            this.radio.select(this.radio.elements[0], false);
        }
    }

    getSummary(): Summary {
        return { title: this.summaryTitle, value: this.display(this.radio.getSelected().id) };
    }
}

export class StringRadioWidget extends RadioWidget<string> {
    getValue(): string | null {
        const selectedId: string | null = this.radio.getSelectedId();
        if (this.datasetElement && selectedId === this.datasetElement.id) {
            return null;
        } else {
            return selectedId;
        }
    }
}

const enum SlackAttachmentsSetting {
    FETCH = "FETCH",
    NO_FETCH = "NO_FETCH",
    DATASET_DEFAULT = "DATASET_DEFAULT",
}

export class SlackAttachmentsWidget extends RadioWidget<boolean> {
    constructor(includeDatasetOptions: boolean) {
        super({
            title: "Slack attachments",
            summaryTitle: "Slack attachments",
            options: [SlackAttachmentsSetting.FETCH, SlackAttachmentsSetting.NO_FETCH].map(
                (id: SlackAttachmentsSetting): BasicRadio.Element => ({
                    id: id,
                    display: SlackAttachmentsWidget.description(id),
                }),
            ),
            display: (id: string): string => SlackAttachmentsWidget.description(id),
            datasetValue: (ds: Dataset): string =>
                ds.config.fetch_slack_attachments
                    ? SlackAttachmentsSetting.FETCH
                    : SlackAttachmentsSetting.NO_FETCH,
            help: "This setting only applies to Slack data",
            datasetElement: includeDatasetOptions
                ? {
                      id: SlackAttachmentsSetting.DATASET_DEFAULT,
                      display: SlackAttachmentsWidget.description(
                          SlackAttachmentsSetting.DATASET_DEFAULT,
                      ),
                  }
                : undefined,
        });
    }

    override getValue(): boolean | null {
        const selectedId: string | null = this.radio.getSelectedId();
        if (selectedId === SlackAttachmentsSetting.FETCH) {
            return true;
        } else if (selectedId === SlackAttachmentsSetting.NO_FETCH) {
            return false;
        }
        return null;
    }

    private static description(setting: string): string {
        switch (setting) {
            case SlackAttachmentsSetting.FETCH:
                return slackAttachmentsDisplay(true);
            case SlackAttachmentsSetting.NO_FETCH:
                return slackAttachmentsDisplay(false);
            case SlackAttachmentsSetting.DATASET_DEFAULT:
                return "Dataset default";
            default:
                return "Unknown";
        }
    }
}

export class ChatSegmentationWidget extends StringRadioWidget {
    constructor() {
        super({
            title: [
                "Segmentation",
                Dom.div(
                    { class: "setting-description" },
                    "All uploaded chat conversations will be divided into segments for review and "
                        + "production in Everlaw",
                ),
            ],
            summaryTitle: "Segmentation",
            options: [
                {
                    id: ChatSegmentationOption.MAX_100,
                    display: chatSegmentationDisplay(ChatSegmentationOption.MAX_100),
                    subcontent: Dom.div(
                        { class: "v-spaced-8" },
                        Dom.div("Each chat segment will contain up to 100 messages"),
                        // This banner will be in place until we replace 100-message segmentation
                        // with our new segmentation strategy.
                        Dom.div(
                            // Override the Radio subcontent color.
                            { class: "bb-text--color-primary" },
                            wrapReactComponent(InlineBanner, {
                                children:
                                    "This will not apply to Slack conversations, which will always "
                                    + "use daily segmentation",
                                icon: createElement(ReactIcon.InfoCircle, {
                                    size: 20,
                                    "aria-hidden": true,
                                }),
                            }).getNode(),
                        ),
                    ),
                },
                {
                    id: ChatSegmentationOption.DAILY,
                    display: chatSegmentationDisplay(ChatSegmentationOption.DAILY),
                    subcontent:
                        "Each chat segment will contain messages sent in a single 24-hour period "
                        + "in the upload time zone",
                },
            ],
            display: (radioElementId: string): string => chatSegmentationDisplay(radioElementId),
            datasetValue: (ds: Dataset): string => ds.config.chat_segmentation_option,
        });
    }
}

/**
 * Base class for a single-selector config widget.
 */
abstract class SelectorWidget implements AdvancedConfigWidget {
    title: Dom.Content[];
    onChange: () => void;

    private readonly selector: SingleSelect<Base.Primitive<string>>;

    /**
     * @param datasetElement - An optional element we want to add to this selector to indicate a
     *     "dataset default" fallback element. If provided, this element will be added, sorted to
     *     the top, and, if selected, will generate a `null` value.
     * @param initialSelected - What (optional) element should we select by default (on
     *     creation/reset)? This defaults to the datasetElement, if provided.
     */
    protected constructor(
        private readonly name: string,
        elements: Base.Primitive<string>[],
        private readonly datasetElement: Base.Primitive<string>,
        title: Dom.Content,
        private readonly initialSelected: Base.Primitive<string> = datasetElement,
    ) {
        if (this.datasetElement) {
            elements.unshift(this.datasetElement);
        }
        this.selector = new SingleSelect({
            elements: elements,
            placeholder: `Select optional ${name}`,
            textBoxLabelContent: title,
            initialSelected: initialSelected,
            popup: "after",
            headers: false,
            selectOnSame: !!initialSelected,
            onBlur: () => {
                if (!this.selector.getValue()) {
                    this.selector.clear();
                }
            },
            onChange: () => {
                this.onChange
                    && this.initialSelected
                    && this.selector.getValue()
                    && this.selector.getValue().id !== this.initialSelected.id
                    && this.onChange();
            },
            onSelect: () => {
                this.selector.blur();
            },
            textBoxParams: { clearMark: !initialSelected },
            clearOnEmptyText: !initialSelected,
            comparator: (n, m) => {
                // Sort the default option to the top.
                if (n === datasetElement) {
                    return m === datasetElement ? 0 : -1;
                } else if (m === datasetElement) {
                    return 1;
                }
                return this.compare(n.name, m.name);
            },
        });
    }

    getNode(): HTMLElement {
        return this.selector.getNode();
    }

    getValue(): string | null {
        return this.getSelectedId();
    }

    reset(ds?: Dataset): void {
        if (ds) {
            const dsVal = this.datasetValue(ds);
            if (dsVal) {
                this.selector.select(new Base.Primitive(dsVal));
            } else {
                this.selector.select(this.initialSelected);
            }
        } else if (this.initialSelected) {
            this.selector.select(this.initialSelected);
        } else {
            this.selector.clear();
            this.selector.unselect();
        }
    }

    getSummary(): Summary {
        const elem = this.selector.getValue();
        if (!elem) {
            return { value: `No ${this.name}` };
        } else {
            return { title: Str.capitalize(this.name), value: elem.name };
        }
    }

    getSelector(): SingleSelect<Base.Primitive<string>> {
        return this.selector;
    }

    protected abstract compare(n: string, m: string): number;

    protected abstract datasetValue(ds: Dataset): string;

    private getSelectedId(): string | null {
        let elem = this.selector.getValue();
        if (elem === this.datasetElement) {
            elem = null;
        }
        return elem ? elem.id : null;
    }
}

export function makeDatasetDetailsLearnMore(inline: boolean = true): HTMLElement {
    const learnMore = Dom.a(
        {
            href: "https://support.everlaw.com/hc/en-us/articles/360000025051-Uploading-Native-Data-to-Everlaw#h_01ESW6Z6J8G0DGVB123HNZFRC3",
            rel: "noopener noreferrer",
            target: "_blank",
            class:
                "everblue-link process-settings__learn-more "
                + (inline ? "process-settings__learn-more--inline" : ""),
        },
        "Learn more",
    );
    const focusDiv = FocusDiv.makeFocusable(learnMore, "focus-text-style");
    focusDiv.registerDestroyable(
        Input.fireCallbackOnKey(focusDiv.node, [Input.ENTER], () => learnMore.click()),
    );
    return learnMore;
}

export class OcrLanguageWidget extends SelectorWidget {
    private static readonly DATASET_LANGUAGE = new Base.Primitive("Dataset default");

    readonly learnMore: HTMLElement;

    constructor(
        includeDataset: boolean,
        inline: boolean = true,
        title: Dom.Content[] = ["OCR language"],
    ) {
        super(
            "OCR language",
            OCR_LANGUAGES.getAll(),
            // If we have the "dataset default" option, pass it.
            includeDataset ? OcrLanguageWidget.DATASET_LANGUAGE : null,
            title,
            // If we have the "dataset default" option, it's selected to start; otherwise we select
            // "Autodetect".
            includeDataset
                ? OcrLanguageWidget.DATASET_LANGUAGE
                : OCR_LANGUAGES.get(OCR_AUTODETECT_ID),
        );
        this.learnMore = makeDatasetDetailsLearnMore(inline);
        this.title = [this.getSelector().tb.getLabel()];
    }

    protected override compare(n: string, m: string): number | 0 | -1 {
        // Sort autodetect to the top.
        if (n === OCR_AUTODETECT_NAME) {
            return m === OCR_AUTODETECT_NAME ? 0 : -1;
        }
        return m === OCR_AUTODETECT_NAME ? 1 : Cmp.strCI(n, m);
    }

    protected override datasetValue(): string {
        // Don't inherit the language from the previous dataset!
        return OCR_AUTODETECT_ID;
    }
}

const pageSizeHelp =
    "Everlaw will generate PDFs with the selected page size for documents that do not have an "
    + "explicit size (e.g. emails). Documents with an explicit page size will keep those sizes.";

export class PageSizeWidget extends SelectorWidget {
    private static readonly DATASET_PAGESIZE = new Base.Primitive("Dataset default");

    readonly help: string = pageSizeHelp;

    constructor(includeDataset: boolean, title: Dom.Content[] = ["Page size"]) {
        super(
            "Page size",
            // Retrieve all possible page sizes (A4 or Letter at the time of this comment).
            Object.keys(PageSizeOption).map((key) => new Base.Primitive(PageSizeOption[key])),
            // If we have the "dataset default" option, pass it.
            includeDataset ? PageSizeWidget.DATASET_PAGESIZE : null,
            title,
            // If we have the "dataset default" option, it's selected to start; otherwise we select
            // the project's default page size.
            includeDataset
                ? PageSizeWidget.DATASET_PAGESIZE
                : new Base.Primitive(
                      Project.CURRENT ? Project.CURRENT.defaultPageSize : PageSizeOption.LETTER,
                  ),
        );
        this.title = [this.getSelector().tb.getLabel()];
    }

    protected override compare(n: string, m: string): number {
        return Cmp.strCI(n, m);
    }

    protected override datasetValue(ds: Dataset): string {
        return ds.config.page_size;
    }
}

const tzHelp =
    "Select the timezone from which the documents were originally produced. By default, this "
    + "timezone will be applied to extracted dates (e.g. metadata) that don't specify an explicit "
    + "timezone. If no timezone is selected, no timezone will be assumed.";

export class TimezoneWidget extends SelectorWidget {
    private static readonly DATASET_TIMEZONE = new Base.Primitive("Dataset default");

    readonly help: string = tzHelp;

    constructor(
        includeDataset: boolean,
        defaultTimezone: Base.Primitive<string> = null,
        title: Dom.Content[] = ["Default timezone"],
        initialValue: Base.Primitive<string> = null,
    ) {
        super(
            "Default timezone",
            Base.wrapPrimitives(
                moment.tz.names().filter((n) => !DateUtil.unSupportedMomentTimezones.has(n)),
            ),
            includeDataset ? TimezoneWidget.DATASET_TIMEZONE : defaultTimezone,
            title,
            includeDataset ? undefined : initialValue,
        );
        this.title = [this.getSelector().tb.getLabel()];
    }

    protected override compare(n: string, m: string): number {
        if (n in TimezonesCommon.forProcessing) {
            return m in TimezonesCommon.forProcessing ? Cmp.strCI(n, m) : -1;
        }
        return m in TimezonesCommon.forProcessing ? 1 : Cmp.strCI(n, m);
    }

    protected override datasetValue(ds: Dataset): string {
        return ds.config.timezone;
    }
}

/**
 * Widget for specifying an exception to any processing timeouts.
 */
export class TimeoutWhitelistWidget implements AdvancedConfigWidget {
    readonly title: Dom.Content = "Timeout whitelist";
    onChange: () => void = () => {
        /* nop */
    };

    private readonly toDestroy: Util.Destroyable[] = [];
    private readonly inputTextBox: Validated.Text;

    constructor(processing?: boolean) {
        const whitelistIcon = new Icon("info-circle-20", {
            tooltip: [
                Dom.span("Any command run by the worker via "),
                Dom.span({ style: { fontFamily: "monospace" } }, "check_output"),
                Dom.span(
                    " will not time out if all of the provided space-separated "
                        + "terms occur in the command.",
                ),
            ],
        });
        this.inputTextBox = new Validated.Text({
            name: "timeout whitelist",
            textBoxLabelPosition: "above",
            textBoxLabelContent: Dom.div({ class: "h-spaced-8", style: { position: "relative" } }, [
                Dom.span({ style: { fontWeight: processing ? 400 : 600 } }, "Timeout whitelist"),
                whitelistIcon.getNode(),
            ]),
            onChange: () => this.onChange?.(),
            trimTrailingWhitespace: true,
            validator: () => {
                // This will be stored as json in a VARCHAR(64) column for
                // AdvancedProcessingOptions.java.
                return !processing || this.getJsonValue().length < 64;
            },
            invalidMessage: "Whitelist is too long",
        });
        this.toDestroy.push(whitelistIcon, this.inputTextBox);
    }

    private static getArray(raw: string): string[] {
        return raw?.split(" ").filter((sub) => !!sub) || [];
    }

    getNode(): HTMLElement {
        return this.inputTextBox.getNode();
    }

    getValue(): string[] {
        return TimeoutWhitelistWidget.getArray(this.inputTextBox?.getValue());
    }

    reset(): void {
        this.inputTextBox.reset();
    }

    getSummary(): Summary {
        const whitelist = this.getValue();
        return whitelist.length
            ? { title: "Timeout whitelist", value: whitelist.join("; ") }
            : { value: "No timeout whitelist" };
    }

    getJsonValue(): string {
        return JSON.stringify(this.getValue());
    }

    destroy(): void {
        Util.destroy(this.toDestroy);
    }
}
