import { AccessRestrictions, AccessRestrictionsEntityType } from "Everlaw/AccessRestrictions";
import { ObjJson } from "Everlaw/Base";
import { CreateRequestDemoForm } from "Everlaw/MarketoForm";
import { applyDefaultSearchGroupingToEql } from "Everlaw/EqlUtil";
import {
    MinimalOrganization,
    OrganizationId,
    ParentOrganization,
} from "Everlaw/MinimalOrganization";
import { initOracle, toggleOracleVisible } from "Everlaw/Oracle/OraclePanel";
import { Arr } from "core";
import Base = require("Everlaw/Base");
import Bugsnag = require("Everlaw/Bugsnag");
import Chronology = require("Everlaw/Chron/Chronology");
import SuperuserAccess = require("Everlaw/Context/CxAdmin/SuperuserAccess");
import Eca = require("Everlaw/Context/Eca");
import SbFree = require("Everlaw/Context/SbFree");
import SbFreeProjectSelect = require("Everlaw/Context/SbFree/Header/ProjectSelect");
import { Constants as C, Is, Str } from "core";
import { Organization } from "Everlaw/Organization";
import Database = require("Everlaw/Database");
import DatabaseField = require("Everlaw/DatabaseField/DatabaseField");
import DatabaseFieldUtil = require("Everlaw/DatabaseField/DatabaseFieldUtil");
import Dom = require("Everlaw/Dom");
import Downtime = require("Everlaw/Downtime");
import Impersonate = require("Everlaw/Impersonate");
import Input = require("Everlaw/Input");
import Message = require("Everlaw/Messaging/Message");
import MessageNotifications = require("Everlaw/Messaging/MessageNotifications");
import Multiplex = require("Everlaw/Multiplex");
import Perm = require("Everlaw/PermissionStrings");
import PredictionModel = require("Everlaw/PredictionModel");
import Processing = require("Everlaw/Model/Processing/Processing");
import Project = require("Everlaw/Project");
import Property = require("Everlaw/Property");
import Rest = require("Everlaw/Rest");
import Task = require("Everlaw/Task");
import dojo_cookie = require("dojo/cookie");
import dojo_on = require("dojo/on");
import ls = require("Everlaw/LocalStorage");
import UI = require("Everlaw/UI");
import IconNode = require("Everlaw/UI/Icon");
import InputDialog = require("Everlaw/UI/InputDialog");
import PopoverMenuNode = require("Everlaw/UI/PopoverMenu");
import QueryDialog = require("Everlaw/UI/QueryDialog");
import SingleSelect = require("Everlaw/UI/SingleSelect");
import TooltipNode = require("Everlaw/UI/Tooltip");
import User = require("Everlaw/User");
import Util = require("Everlaw/Util");
import WalkMe = require("Everlaw/WalkMe");
import { ColorTokens, PopoverPlacement } from "design-system";
import { HelpMenu, HelpMenuHandle, HelpMenuProps, initHelpMenuRecommendations } from "Everlaw/Help";
import { Recommendations } from "Everlaw/SmartOnboarding/RecommendationConstants";
import * as RecommendationInit from "Everlaw/SmartOnboarding/RecommendationInit";
import { SystemPermission } from "Everlaw/SystemPermission";
import { TimeLogger } from "Everlaw/TimeLogger";
import { createToastNode } from "Everlaw/ToastBoxManager";
import { makeFocusable } from "Everlaw/UI/FocusDiv";
import { wrapReactComponent } from "Everlaw/UI/ReactWidget";
import { EVERID, setEverId } from "Everlaw/EverAttribute/EverId";
import { MutableRefObject } from "react";
import * as HeaderPerms from "Everlaw/HeaderPermissionsUtil";

let onHeaderObjectsSet: () => void;
const headerObjectsPromise = new Promise<void>((resolve) => {
    onHeaderObjectsSet = resolve;
});

const headerIcons: headerIconMenus = {
    adminMenu: undefined,
    analyticsMenu: undefined,
    dataTransferMenu: undefined,
    sbMenu: undefined,
    sbMenuIds: [],
};

/**
 * Chronology and PredictionModel objects are required to properly initialize the header, but the
 * order in which the responses for header.jsp and the page-specific jsp is received isn't
 * guaranteed. We don't want to fetch/set these objects in both places, not only for performance
 * reasons, but also if the page-specific init code modifies any of these objects before
 * Header.init() runs, then the header's Base.set() would clobber those changes.
 *
 * Therefore, we provide this promise that resolves once the header objects have been set. Page-
 * specific jsps should not fetch/set chrons or models, and instead should call setHeaderObjects()
 * and perform the page init in the return promise's then() method.
 *
 * Also, any objects that depend on chrons or models (for example, Argument and Deposition depend on
 * Chronology) should be passed as json arguments to the page's init() function and Base.Set() in
 * the then() part of the promise, to guarantee the dependency is satisfied.
 */
export function setHeaderObjects(): Promise<void> {
    return headerObjectsPromise;
}

/**
 * This function may only be called in contexts where there is a logged-in user (User.me is assumed
 * to be non-null).
 */
export async function createOrgSelector(currentOrgId: OrganizationId) {
    if (!User.me) {
        Bugsnag.notify(Error("Header.createOrgSelector called in a context with no User.me"));
        return;
    }
    const container = Dom.byId("header-org-selector");
    const name = Dom.byId("header-org-name");

    Dom.style(new IconNode("caret-down-20", { parent: name }), {
        marginLeft: "10px",
        verticalAlign: "middle",
    });

    const tooltip = new TooltipNode(name, "Change Organization");

    if (!JSP_PARAMS.Organization) {
        throw new Error("Organization undefined for org selector");
    }
    if (!JSP_PARAMS.Organization.viewableIds) {
        throw new Error("Viewable orgs undefined for org selector");
    }
    if (!JSP_PARAMS.Organization.subOrgsEnabledIds) {
        throw new Error("Parent orgs undefined for org selector");
    }
    const viewableOrgs = Base.get(MinimalOrganization, JSP_PARAMS.Organization.viewableIds);
    const subOrgsEnabledIds = new Set(JSP_PARAMS.Organization.subOrgsEnabledIds);
    const parentOrgs: ParentOrganization[] = [];
    const nonParentOrgs: MinimalOrganization[] = [];
    viewableOrgs.forEach((org) => {
        if (subOrgsEnabledIds.has(org.id)) {
            parentOrgs.push(new ParentOrganization(org));
        } else {
            nonParentOrgs.push(org);
        }
    });
    const select = new SingleSelect<MinimalOrganization | ParentOrganization>({
        elements: parentOrgs.length === 0 ? nonParentOrgs : [parentOrgs, nonParentOrgs],
        headers: true,
        nameMap: {
            MinimalOrganization: "Organizations",
            ParentOrganization: "Parent Organizations",
        },
        popupClass: "project-popup",
        popup: "after",
        menuMaxHeight: "400px",
        onBlur: () => {
            Dom.hide(container);
            Dom.show(name);
        },
        onSelect: (org: MinimalOrganization) => {
            ga_event("Navbar", "Change Admin Org");
            const loc = location.href.indexOf("#");
            const hash = loc !== -1 ? location.href.substr(loc) : "";
            Organization.fetchById(org.id).then((fullOrg) => {
                location.href = fullOrg.isAdminSuspended()
                    ? `/org.do?orgId=${org.id}`
                    : `/org.do?orgId=${org.id}${hash}`;
            });
        },
    });

    // Eventually, we may want to introduce an `Organization.CURRENT` that we could use here instead
    // of reaching out to the backend. For the time being, this should be very fast, and in fact is
    // consistent with our project selector which is also loaded async.
    const org = await Organization.fetchById(currentOrgId);

    const adminSuspendedTag = Dom.span(
        { class: "org-status-tag ellipsed suspended" },
        "NONPAYMENT SUSPENSION",
    );
    const adminSuspendedTagSpan = Dom.create(
        "span",
        {
            class: "project-display__status",
            content: [adminSuspendedTag],
        },
        name,
        "first",
    );
    Dom.show(adminSuspendedTagSpan, org.isAdminSuspended());

    const executeFunction = () => {
        Dom.hide(name);
        Dom.show(container);
        tooltip.close();
        select.select(Base.get(MinimalOrganization, currentOrgId), true, true);
        select.focus();
    };
    dojo_on(name, Input.tap, () => executeFunction());
    const focusDiv = makeFocusable(Dom.byId("header-main-text"), "focus-with-space-style");
    focusDiv.registerDestroyable(
        Input.fireCallbackOnKey(focusDiv.node, [Input.ENTER], () => executeFunction()),
    );

    Dom.place(select, container);
}

/**
 * Display the drop arrow and connect to click events on both the project name and the arrow.
 * Clicking will trigger a load of all visible projects (if required) and the menu to appear.
 * The point of the arrow is simply to indicate to the user that it is clickable.
 *
 * This function may only be called in contexts where there is a logged-in user (User.me is assumed
 * to be non-null).
 */
export function createProjectSelector() {
    if (!User.me) {
        Bugsnag.notify(Error("Header.createProjectSelector called in a context with no User.me"));
        return;
    }
    let projectSelector: SingleSelect<Project>;
    const name = Dom.byId("header-project-name");
    const loading = Dom.byId("header-project-loading");
    const selector = Dom.byId("header-project-selector");
    const tooltip = new TooltipNode(
        name,
        `Change project${SbFree.inContext() ? " or create new project" : ""}`,
    );
    const adminSuspendedTag = Dom.span(
        { class: "project-status-tag ellipsed suspended" },
        "NONPAYMENT SUSPENSION",
    );
    const adminSuspendedTagSpan = Dom.create(
        "span",
        {
            class: "project-display__status",
            content: [adminSuspendedTag],
        },
        name,
    );
    const suspendedTag = Dom.span(
        { class: "project-status-tag ellipsed suspended" },
        Project.status.SUSPENDED,
    );
    const suspendedTagSpan = Dom.create(
        "span",
        {
            class: "project-display__status",
            id: "project-display__admin-status-dropdown",
            content: [suspendedTag],
        },
        name,
    );
    const scheduledForSuspensionTag = Dom.span(
        { class: "project-status-tag ellipsed suspended" },
        Project.status.SUSPENSION_SCHEDULED,
    );
    const scheduledForSuspensionTagSpan = Dom.create(
        "span",
        {
            class: "project-display__status",
            id: "project-display__admin-status-dropdown",
            content: [scheduledForSuspensionTag],
        },
        name,
    );

    Dom.addClass(
        new IconNode("caret-down-20", { alt: "change project", parent: name }),
        "header__project-dropdown-icon",
    );
    Dom.show(suspendedTagSpan, Project.CURRENT.suspended);
    Dom.show(
        scheduledForSuspensionTagSpan,
        Project.CURRENT.getDatabase().isScheduledForSuspension(),
    );
    Dom.show(adminSuspendedTagSpan, Project.CURRENT.isImpactedByAdminSuspension());

    // When the user clicks, we want to show the project selector and hide the normal project name.
    const executeFunction = () => {
        if (!Project.allLoaded) {
            // Show the loading message while we load projects.
            Dom.hide(name);
            Dom.show(loading);
        }
        Project.loadVisibleProjectsAndParentEntities().then(() => {
            Dom.hide(name);
            Dom.hide(loading);
            Dom.show(selector);
            if (!projectSelector) {
                const elements = Base.get(Project).filter((p) => {
                    return (
                        Project.keepVisible(p)
                        && (!p.suspended
                            || User.me.can(Perm.DB_ADMIN, p, User.Override.ELEVATED_OR_ORGADMIN))
                        && (User.me.has(SystemPermission.APPROVE_DATABASE_AND_PROJECT_DELETIONS)
                            || (!p.deletionRequested
                                && User.me.can(Perm.READ, p, User.Override.ELEVATED_OR_ORGADMIN)))
                    );
                });

                // Create the FilteringSelect the first time the user clicks on the project name.
                projectSelector = new SbFreeProjectSelect({
                    // All projects in the organization are serialized to Org Admins, but they don't
                    // necessarily have read access to all of them.
                    elements,
                    headers: true,
                    getHeader: (p) => {
                        if (p.isImpactedByAdminSuspension()) {
                            return "Projects suspended for nonpayment";
                        } else if (p.suspended) {
                            return "Suspended projects";
                        } else {
                            return "Active projects";
                        }
                    },
                    pluralize: false,
                    popupClass: "project-popup",
                    popup: "after",
                    menuMaxHeight: "400px",
                    onBlur: function () {
                        Dom.hide(selector);
                        Dom.show(name);
                    },
                    classOrder: [
                        "Active projects",
                        "Suspended projects",
                        "Projects suspended for nonpayment",
                    ],
                    classOrderOnSearch: false,
                    retainDefaultSearch: true,
                    getFilterValues: (p) => {
                        return [
                            p.display(),
                            p.suspended || p.isImpactedByAdminSuspension() ? "suspended" : "active",
                            ...Object.values(p.getDatabase().databaseFieldValues).map(
                                (fieldValue) => fieldValue.display(),
                            ),
                        ];
                    },
                    selectOnSame: true,
                    onSelect: function (project: Project) {
                        ga_event("Navbar", "Change Case");
                        const goToProject = () => {
                            // hide the list
                            projectSelector.blur();
                            // don't do anything if we're navigating to the current project
                            if (Project.CURRENT.id === project.id) {
                                return;
                            }
                            project.goto(getProjectSelectPage(project));
                            Dom.setContent(loading, "Loading " + project.display() + "...");
                            Dom.hide(name);
                            Dom.show(loading);
                        };
                        if (
                            (User.me.hasExplicitCxAdminRole() || User.me.hasExplicitProdAdminRole())
                            && !User.me.can(Perm.READ, project, User.Override.ORGADMIN)
                            && !User.me.hasGrantedAccessToProject(project)
                        ) {
                            SuperuserAccess.approveProjectPage(goToProject);
                        } else {
                            goToProject();
                        }
                    },
                    textBoxAriaLabel: "Select project",
                    prepRowElement: (e) => {
                        let db = null;
                        if (e instanceof Project && e.getDatabase()) {
                            db = e.getDatabase();
                        }
                        return DatabaseFieldUtil.prepRowElement(db || e);
                    },
                });
                Dom.place(projectSelector, selector);
            } else {
                // reset projectSelector to initial state
                projectSelector.filter("");
            }
            // manually set inital value for FilteringSelect
            projectSelector.select(Project.CURRENT, true, true);
            // Close project tooltip because it blocks the menu.
            tooltip.close();
            projectSelector.focus();
        });
    };
    dojo_on(name, Input.tap, () => {
        executeFunction();
    });
    const selectorFocusDiv = makeFocusable(
        Dom.byId("header-main-text"),
        "focus-with-space-style",
        "after",
    );
    selectorFocusDiv.registerDestroyable(
        Input.fireCallbackOnKey(selectorFocusDiv.node, [Input.ENTER, Input.SPACE], () =>
            executeFunction(),
        ),
    );
    Base.subscribe(Project, (projects, removed) => {
        if (!projectSelector) {
            return;
        }
        if (removed) {
            projectSelector.removeMultiple(projects, true);
        } else {
            // When removing something from projectSelector, it only searches the header the item
            // that it being removed would have (e.g., if trying to remove a project that is
            // suspended, only projects listed under "Suspended project" will be searched). This
            // is a problem when a project switches from active to suspended or vise-versa because
            // the project's header no longer matches up with the header the project should have.
            // Therefore, we flip the suspended field and remove any old copies of the project
            // before adding the new copies in.
            projects.forEach((p) => (p.suspended = !p.suspended));
            projectSelector.removeMultiple(projects, true);
            projects.forEach((p) => (p.suspended = !p.suspended));
            projectSelector.addMultiple(projects, true);
        }
    });
}

function getCurrentPage() {
    return Str.substrBetweenLast("/", ".do", location.href);
}

const backToHomePages = Arr.toSet(["profile", "chron", "argument", "deposition"]);
function getProjectSelectPage(project: Project) {
    if (project.suspended) {
        return "database";
    }
    if (SbFree.inContext()) {
        return "home";
    }
    const currentPage = getCurrentPage();
    return backToHomePages.has(currentPage) ? "home" : currentPage;
}

export function logout() {
    Dom.ifNodeExists("nav-logout-form", (n) => {
        const logoutForm = n as HTMLFormElement;
        gaNavEvent("Log Out");
        logoutForm._csrf.value = dojo_cookie("XSRF-TOKEN");
        logoutForm.submit();
    });
}

function makeHelpIconAccessible(): void {
    const helpIcon = Dom.byId("nav-help");
    Dom.addClass(helpIcon, "bb-focus-visible-pseudo");
    helpIcon.setAttribute("role", "button");
    helpIcon.setAttribute("tabindex", "0");
}

const initHelpMenu = () => {
    const handleRef: MutableRefObject<HelpMenuHandle> = {
        current: { setShow: () => {}, setActive: () => {} },
    };
    const setSettingsMenuShowRef: MutableRefObject<(show: boolean) => void> = { current: () => {} };
    const helpMenuProps: HelpMenuProps = {
        helpMenuIconRef: { current: Dom.byId("nav-help") },
        handleRef: handleRef,
        setSettingsShowRef: setSettingsMenuShowRef,
    };
    const helpNode = wrapReactComponent(HelpMenu, helpMenuProps);
    initHelpMenuRecommendations({ helpIconNode: Dom.byId("nav-help"), helpMenuProps });
    Dom.place(helpNode, Dom.byId("nav-help"));
    makeHelpIconAccessible();
};

export interface HeaderParams {
    helpTitle: string;
    notLive: boolean;
    version: string;
    nextDowntime: number;
    messagePolling: boolean;
    newMessageCount: number;
    legalHoldsReadOnlyAndEmpty: boolean;
    chronologies?: ObjJson;
    predictionModels?: ObjJson;
}

export function init(params: HeaderParams) {
    if (params.chronologies) {
        Base.set(Chronology, params.chronologies);
    }
    if (params.predictionModels) {
        Base.set(PredictionModel, params.predictionModels);
    }
    // Enable Project Oracle if node exists created by navOracle.jsp
    Dom.ifNodeExists("nav-oracle", (oracleIcon) => {
        makeLinkFocusable(oracleIcon);
        Dom.setAttr(oracleIcon, "role", "button");
        Dom.setAttr(oracleIcon, "aria-label", "Navigate to Oracle");
        initOracle();
        const oracleIconDisabled = !User.me.can(Perm.VIEW_ORACLE, Project.getCurrentProject());

        if (oracleIconDisabled) {
            Dom.addClass(oracleIcon, "disabled display-as-disabled");
            new TooltipNode(oracleIcon, "You do not have permission to access Oracle");
        }

        oracleIcon.onclick = () => {
            if (oracleIconDisabled) {
                return;
            }
            toggleOracleVisible();
        };
    });

    // When init is called we know the objects have been Base.set() by header.jsp
    onHeaderObjectsSet();

    if (params.notLive) {
        initDevBanner(params.version);
    }

    initHelpMenu();

    if (params.nextDowntime !== null) {
        Downtime.scheduleDowntimePopups(params.nextDowntime);
    }

    if (params.messagePolling) {
        // Initiate message polling.
        Message.setNewMessagesCount(params.newMessageCount);
    }

    if (JSP_PARAMS.Multiplex) {
        Multiplex.initialize({
            // These handlers must match the default subscriptions in MultiplexController.create()
            "-2": Task.completionHandler,
            "-3": MessageNotifications.notificationHandler,
            "-4": Processing.completionHandler,
        });
    }
    initSmartOnboarding();

    // Create a React root for Toasts
    createToastNode();

    buildUserMenu();

    if (SbFree.inContext()) {
        const requestDemoButtonWrapper = document.getElementById("request-demo-button-wrapper");
        const requestDemoModalComponent = wrapReactComponent(CreateRequestDemoForm, {
            isButton: true,
            isModalVisible: false,
            onModalClose: () => {
                requestDemoModalComponent.updateProps({ isModalVisible: false });
            },
        });

        requestDemoButtonWrapper?.appendChild(requestDemoModalComponent.getNode());
    }

    if (Project.CURRENT && SbFree.inContext()) {
        const perms = HeaderPerms.checkPermissions();
        initWalkMe(perms);
        buildDataTransferMenu(perms, params.legalHoldsReadOnlyAndEmpty);
        // Set tooltips for Storybuilder by Everlaw
        Object.entries({
            help: [params.helpTitle, false],
            // eslint-disable-next-line camelcase
            sb_free_home: ["Project Home", false],
            // eslint-disable-next-line camelcase
            sb_free_settings: ["Settings", false],
        }).forEach(([navId, val]: [string, [string, boolean]]) => {
            const tooltip = val[0];
            const overrideHref = val[1];
            const el = document.getElementById("nav-" + navId) as HTMLAnchorElement | null;
            if (el) {
                if (el.tagName === "A" && el.href) {
                    highlightCurrentPage(el);
                    el.onclick = () => gaNavEvent(tooltip, undefined, overrideHref);
                }
                return new TooltipNode(el, tooltip);
            }
        });
        return;
    }

    // Make the Everlaw logo link keyboard accessible.
    const headerLogoLink = Dom.byId("header-logo-link");
    const logoFocusDiv = makeFocusable(headerLogoLink, "focus-with-space-style");
    logoFocusDiv.registerDestroyable(
        Input.fireCallbackOnKey(logoFocusDiv.node, [Input.ENTER], () => headerLogoLink.click()),
    );

    const diamondNavIds = ["org", "everlaw_admin", "superuser"].map((name) => `nav-${name}`);

    diamondNavIds.forEach((id) => {
        Dom.ifNodeExists(id, makeLinkFocusable);
    });

    // If a CX Admin, Product Admin, or Finance Admin accesses the Superuser page,
    // show a dialog that collects their reason(s) for doing so.
    if (
        User.me
        && (User.me.hasExplicitCxAdminRole()
            || User.me.hasExplicitProdAdminRole()
            || User.me.hasExplicitFinAdminRole())
    ) {
        const currentPage = getCurrentPage();
        const navSuperuser = document.getElementById("nav-superuser");
        // If the user is on /admin.do or /superuser.do, open the dialog when they
        // click the S diamond icon. Otherwise, hide the icon.
        if (currentPage === "admin" || currentPage === "superuser") {
            if (navSuperuser && navSuperuser instanceof HTMLAnchorElement) {
                navSuperuser.onclick = () => {
                    SuperuserAccess.approveSUPage(true);
                    return false;
                };
            }
        } else {
            navSuperuser && Dom.hide(navSuperuser);
        }

        // If the CX Admin or Product Admin directly navigates to superuser.do or a client project
        // page, show the appropriate dialog to record that access on page load.
        // The URL constructor is not supported in IE.
        if (!refreshedPage()) {
            // If navigating from another Everlaw page, we've already shown the dialog.
            // Also, check for the case where they are redirected after logging in.
            const prevURL = document.referrer && new URL(document.referrer);
            const fromEverlaw = prevURL && prevURL.hostname === window.location.hostname;

            if (
                !fromEverlaw
                || prevURL.pathname === "/login.do"
                || prevURL.pathname === "/mfa/authenticate.do"
                || usedForwardBackBtns()
            ) {
                if (currentPage === "superuser") {
                    SuperuserAccess.approveSUPage(false);
                } else if (
                    Project.CURRENT
                    && !User.me.can(Perm.READ, Project.CURRENT, User.Override.ORGADMIN)
                    && !User.me.hasGrantedAccessToProject(Project.CURRENT)
                    && !usedForwardBackBtns()
                ) {
                    SuperuserAccess.approveProjectPage(null, () =>
                        window.location.assign("/admin.do"),
                    );
                }
            }
        }
    }

    // The remaining code is only for project pages with navigation icons. If any page (e.g., the
    // profile page, which is also used for things like password recovery) does not include
    // project security, hide the navigation icon altogether because we don't know which pages the
    // user is allowed to view.

    const navAdmin = document.getElementById("nav-admin");
    if (!navAdmin) {
        return;
    }
    if (!Project.CURRENT || !(Project.CURRENT.security || Project.CURRENT.fullSecurity)) {
        Dom.hide(navAdmin);
        return;
    }

    const perms = HeaderPerms.checkPermissions();

    // Add tooltips to the navigation icons, and set the current one by comparing link targets to
    // the current page.
    Object.entries({
        home: ["Project Home", false],
        search: ["Project Search", false],
        storybuilder: ["Storybuilder", false],
        messages: ["Messages", false],
        // eslint-disable-next-line camelcase
        everlaw_admin: ["Everlaw Admin Home", false],
        superuser: ["Superuser Home", false],
        org: ["Organization Home", false],
        help: [params.helpTitle, false],
        // eslint-disable-next-line camelcase
        sb_free_home: ["Story Home", false],
        settings: ["Project Settings", false],
        // eslint-disable-next-line camelcase
        eca_uploads: [
            perms.canUpload ? "Uploads" : "You do not have permission to access this page",
            !perms.canUpload,
        ],
    }).forEach(([navId, val]: [string, [string, boolean]]) => {
        const tooltip = val[0];
        const overrideHref = val[1];
        const el = document.getElementById("nav-" + navId) as HTMLAnchorElement | null;
        if (el) {
            if (el.tagName === "A" && el.href) {
                highlightCurrentPage(el);
                el.onclick = () => gaNavEvent(tooltip, undefined, overrideHref);
            }
            return new TooltipNode(el, tooltip);
        }
    });

    initWalkMe(perms);

    headerIcons.dataTransferMenu = buildDataTransferMenu(perms, params.legalHoldsReadOnlyAndEmpty);

    headerIcons.analyticsMenu = buildAnalyticsMenu(perms);

    buildStorybuilderMenu();

    headerIcons.adminMenu = buildAdminMenu(perms);
    const projectSettings = headerIcons.adminMenu.getItem("settings");
    setEverId(projectSettings?.node as Element, EVERID.HEADER.PROJECT_MANAGEMENT_PROJECT_SETTINGS);
    const projectAnalytics = headerIcons.adminMenu.getItem("analytics");
    setEverId(projectAnalytics?.node as Element, EVERID.HEADER.PROJECT_MANAGEMENT_ANALYTICS_TAB);

    initializeCreateGroupsAddUsersRecommendation();
    initializeRecommendToViewNewUsers();
    initializeRecommendToViewAnalytics();
    initializeCreateProductionProtocol();
    initializeViewNewDocsRec();
    initializeCreatePersistentHighlights();
    initializeAddCodesCategories();
    initializeSearchByProject();
    initializeCreateFolderRecommendation();

    Recommendations.VIEW_NEW_CODES.getStep(0).reregisterDisplayer({
        node: EVERID.HEADER.PROJECT_MANAGEMENT_ICON,
        placement: [PopoverPlacement.BOTTOM],
        nextFunction: () => headerIcons.adminMenu.open(),
        nextFunctionInverse: () => headerIcons.adminMenu.close(),
    });

    Recommendations.VIEW_NEW_CODES.getStep(1).registerDisplayer({
        node: EVERID.HEADER.PROJECT_MANAGEMENT_PROJECT_SETTINGS,
        placement: [PopoverPlacement.RIGHT, PopoverPlacement.LEFT],
        redirectNext: Project.CURRENT.urlFor("settings"),
        onActivate: () => {
            headerIcons.adminMenu.open();
            headerIcons.adminMenu.getItem("settings").node.removeAttribute("href");
            headerIcons.adminMenu.setDisableFocus(true);
        },
        nextFunctionInverse: () => headerIcons.adminMenu.open(),
        onRecommendationClose: () => {
            headerIcons.adminMenu
                .getItem("settings")
                .node.setAttribute("href", Project.CURRENT.urlFor("settings"));
            headerIcons.adminMenu.setDisableFocus(false);
        },
    });

    Recommendations.CREATE_REDACTION_STAMPS.getStep(0).registerDisplayer({
        node: EVERID.HEADER.PROJECT_MANAGEMENT_ICON,
        placement: [PopoverPlacement.BOTTOM],
        nextFunction: () => {
            headerIcons.adminMenu.open();
        },
        shouldSkip: Util.onProjectSettingsPage,
    });

    const href = Project.CURRENT.urlFor("settings");
    Recommendations.CREATE_REDACTION_STAMPS.getStep(1).registerDisplayer({
        node: EVERID.HEADER.PROJECT_MANAGEMENT_PROJECT_SETTINGS,
        placement: [PopoverPlacement.RIGHT, PopoverPlacement.LEFT],
        redirectNext: href,
        onActivate: () => {
            headerIcons.adminMenu.open();
            headerIcons.adminMenu.getItem("settings").node.removeAttribute("href");
            headerIcons.adminMenu.setDisableFocus(true);
        },
        nextFunctionInverse: () => {
            headerIcons.adminMenu.open();
        },
        onRecommendationClose: () => {
            headerIcons.adminMenu.getItem("settings").node.setAttribute("href", href);
            headerIcons.adminMenu.setDisableFocus(false);
        },
        shouldSkip: Util.onProjectSettingsPage,
    });

    updateIcons(perms);

    if (!User.me.impersonatorUsername) {
        new TimeLogger(window);
    }

    if (User.me.isSuperuserOrImpersonating()) {
        // when a superuser or impersonator is on a sensitive data org's project page
        AccessRestrictions.fromEntity(
            AccessRestrictionsEntityType.PROJECT,
            Project.CURRENT.id,
        ).then((accessRestrictions) => {
            if (
                shouldShowSuperUserWarning(accessRestrictions)
                && window.location.pathname !== "/profile.do"
            ) {
                addSuperUserWarning(accessRestrictions);
            }
        });
    }

    // Adds the focus styling class to the header links.
    const headerIconsContainer = Dom.node("header-nav");
    const headerIconsSubGroups = headerIconsContainer.children;
    for (let groupNum = 0; groupNum < headerIconsSubGroups.length; groupNum++) {
        const headerIconsSubGroup = headerIconsSubGroups[groupNum];
        const headerIcons = headerIconsSubGroup.children;
        for (let iconNum = 0; iconNum < headerIcons.length; iconNum++) {
            const navButton = headerIcons[iconNum] as HTMLElement;
            // On certain pages, elements in the page body will hijack a keystroke and links
            // will not be followed. By preventing event propagation when one of the
            // icon-links in the header is selected, we prevent hijacking and the links work
            // as intended.
            if (Dom.hasAttr(navButton, "href") && diamondNavIds.indexOf(navButton.id) < 0) {
                makeLinkFocusable(navButton);
            }
        }
    }

    // Display pinned database field values if we are currently on a project page.
    const pinnedFieldsContainer = document.getElementById("header-pinned-database-fields");
    if (pinnedFieldsContainer && Project.CURRENT) {
        Rest.get(Project.CURRENT.url("getDatabaseAndPinnedFields.rest")).then(
            (data: { database: any; fields: any[] }) => {
                Base.set(Database, data.database);
                Base.set(DatabaseField, data.fields);
                const pinnedFieldsDisplay = DatabaseFieldUtil.getPinnedFieldsHtmlElementForDatabase(
                    Project.CURRENT.getDatabase(),
                );
                if (pinnedFieldsDisplay) {
                    Dom.place(pinnedFieldsDisplay, pinnedFieldsContainer);
                    Dom.ifNodeExists("header-project-name", (n) =>
                        Dom.style(n, "lineHeight", "24px"),
                    );
                    Dom.show(pinnedFieldsContainer);
                }
            },
        );
    }
}

function initializeSearchByProject(): void {
    const search = Dom.byId("nav-search");
    const searchHref = Project.CURRENT.urlFor("search", { view: "search" });
    setEverId(search, EVERID.HEADER.SEARCH_ICON);
    Recommendations.SEARCH_BY_PROJECT.getStep(0).registerDisplayer({
        node: EVERID.HEADER.SEARCH_ICON,
        placement: [PopoverPlacement.BOTTOM],
        onActivate: () => search.removeAttribute("href"),
        onRecommendationClose: () => search.setAttribute("href", searchHref),
        redirectNext: searchHref,
        shouldSkip: Util.onSearchPage,
    });
}

function initializeAddCodesCategories(): void {
    const projectSettings = headerIcons.adminMenu.getItem("settings");
    if (!projectSettings) {
        throw new Error("Expected a project settings item in the admin menu.");
    }
    const projectSettingsHref = projectSettings.href;
    Recommendations.ADD_CODES_CATEGORIES.getStep(0).reregisterDisplayer({
        node: EVERID.HEADER.PROJECT_MANAGEMENT_PROJECT_SETTINGS,
        placement: [PopoverPlacement.RIGHT, PopoverPlacement.LEFT],
        onActivate: () => {
            headerIcons.adminMenu.open();
            headerIcons.adminMenu.getNode().removeAttribute("href");
            headerIcons.adminMenu.setDisableFocus(true);
        },
        onRecommendationClose: () => {
            projectSettings.node.setAttribute("href", projectSettingsHref);
            headerIcons.adminMenu.setDisableFocus(false);
        },
        shouldSkip: Util.onProjectSettingsPage,
        redirectNext: projectSettingsHref,
    });
}

function initializeCreateGroupsAddUsersRecommendation() {
    setEverId(headerIcons.adminMenu.getNode(), EVERID.HEADER.PROJECT_MANAGEMENT_ICON);
    Recommendations.CREATE_GROUPS_ADD_USERS.getStep(0).reregisterDisplayer({
        node: EVERID.HEADER.PROJECT_MANAGEMENT_ICON,
        placement: [PopoverPlacement.BOTTOM, PopoverPlacement.BOTTOM_END],
        nextFunctionInverse: () => headerIcons.adminMenu.close(),
        shouldSkip: Util.onProjectSettingsPage,
    });
    const groupsHash = "tab=groups";
    const href = Project.CURRENT.urlFor("settings") + groupsHash;

    // settings.do is getting called twice on this function call for some reason, even with the href successfully
    // removed.
    Recommendations.CREATE_GROUPS_ADD_USERS.getStep(1).registerDisplayer({
        node: EVERID.HEADER.PROJECT_MANAGEMENT_PROJECT_SETTINGS,
        placement: [PopoverPlacement.RIGHT, PopoverPlacement.LEFT],
        redirectNext: href,
        onActivate: () => {
            headerIcons.adminMenu.getItem("settings")?.node.removeAttribute("href");
            headerIcons.adminMenu.setDisableFocus(true);
            headerIcons.adminMenu.open();
        },
        nextFunctionInverse: () => headerIcons.adminMenu.open(),
        onRecommendationClose: () => {
            headerIcons.adminMenu.getItem("settings")?.node.setAttribute("href", href);
            headerIcons.adminMenu.setDisableFocus(false);
        },
        shouldSkip: Util.onProjectSettingsPage,
        // We're skipping this step if we're on the project settings page, but we also need to
        // make sure we're on the correct tab.
        onBeforeSkip: () => (window.location.hash = groupsHash),
    });
}

function initializeRecommendToViewNewUsers() {
    Recommendations.RECOMMEND_TO_VIEW_NEW_USERS.getStep(0).registerDisplayer({
        node: EVERID.HEADER.PROJECT_MANAGEMENT_ICON,
        placement: [PopoverPlacement.BOTTOM],
        shouldSkip: Util.onProjectSettingsPage,
        nextFunctionInverse: () => headerIcons.adminMenu.close(),
    });

    const href = Project.CURRENT.urlFor("settings");

    Recommendations.RECOMMEND_TO_VIEW_NEW_USERS.getStep(1).registerDisplayer({
        node: EVERID.HEADER.PROJECT_MANAGEMENT_PROJECT_SETTINGS,
        placement: [PopoverPlacement.RIGHT, PopoverPlacement.LEFT],
        redirectNext: href,
        onActivate: () => {
            headerIcons.adminMenu.open();
            headerIcons.adminMenu.getItem("settings").node.removeAttribute("href");
            headerIcons.adminMenu.setDisableFocus(true);
        },
        nextFunctionInverse: () => headerIcons.adminMenu.open(),
        onRecommendationClose: () => {
            headerIcons.adminMenu.getItem("settings").node.setAttribute("href", href);
            headerIcons.adminMenu.setDisableFocus(false);
        },
        shouldSkip: Util.onProjectSettingsPage,
    });
}

function initializeCreatePersistentHighlights() {
    Recommendations.CREATE_PERSISTENT_HIGHLIGHTS.getStep(0).registerDisplayer({
        node: EVERID.HEADER.PROJECT_MANAGEMENT_ICON,
        placement: [PopoverPlacement.BOTTOM],
        nextFunction: () => {
            headerIcons.adminMenu.open();
        },
        nextFunctionInverse: () => {
            headerIcons.adminMenu.close();
        },
        shouldSkip: Util.onProjectSettingsPage,
    });
    const href = Project.CURRENT.urlFor("settings");
    Recommendations.CREATE_PERSISTENT_HIGHLIGHTS.getStep(1).registerDisplayer({
        node: EVERID.HEADER.PROJECT_MANAGEMENT_PROJECT_SETTINGS,
        placement: [PopoverPlacement.RIGHT, PopoverPlacement.LEFT],
        redirectNext: href,
        onActivate: () => {
            headerIcons.adminMenu.getItem("settings").node.removeAttribute("href");
            headerIcons.adminMenu.open();
            headerIcons.adminMenu.setDisableFocus(true);
        },
        onRecommendationClose: () => {
            headerIcons.adminMenu.getItem("settings").node.setAttribute("href", href);
            headerIcons.adminMenu.setDisableFocus(false);
        },
        shouldSkip: Util.onProjectSettingsPage,
    });
}

function initializeRecommendToViewAnalytics() {
    Recommendations.RECOMMEND_TO_VIEW_ANALYTICS.getStep(0).reregisterDisplayer({
        node: EVERID.HEADER.PROJECT_MANAGEMENT_ICON,
        placement: [PopoverPlacement.BOTTOM],
        nextFunction: () => headerIcons.adminMenu.open(),
        nextFunctionInverse: () => headerIcons.adminMenu.close(),
        shouldSkip: Util.onAnalyticsPage,
    });
    const projectSettings = headerIcons.adminMenu.getItem("analytics");
    const href = Project.CURRENT.urlFor("analytics", { tab: "overview" });
    Recommendations.RECOMMEND_TO_VIEW_ANALYTICS.getStep(1).reregisterDisplayer({
        node: EVERID.HEADER.PROJECT_MANAGEMENT_ANALYTICS_TAB,
        placement: [PopoverPlacement.RIGHT, PopoverPlacement.LEFT],
        redirectNext: href,
        onActivate: () => {
            headerIcons.adminMenu.open();
            projectSettings.node.removeAttribute("href");
            headerIcons.adminMenu.setDisableFocus(true);
        },
        onRecommendationClose: () => {
            projectSettings.href = href;
            headerIcons.adminMenu.setDisableFocus(false);
        },
        nextFunctionInverse: () => headerIcons.adminMenu.open(),
        shouldSkip: Util.onAnalyticsPage,
    });
}

function initializeCreateProductionProtocol() {
    const productionIcon = headerIcons.dataTransferMenu.getItem("productions");
    if (productionIcon) {
        setEverId(headerIcons.dataTransferMenu.getNode(), EVERID.HEADER.DATA_TRANSFER_ICON);
        const productionMenu = productionIcon.node as HTMLAnchorElement;
        setEverId(productionMenu, EVERID.HEADER.DATA_TRANSFER_PRODUCTIONS);
    }
}

function initializeViewNewDocsRec() {
    const uploadsItem = headerIcons.dataTransferMenu.getItem("uploads");
    if (uploadsItem) {
        Recommendations.VIEW_NEW_DOCS.getStep(0).registerDisplayer({
            node: EVERID.HEADER.DATA_TRANSFER_ICON,
            placement: [PopoverPlacement.BOTTOM],
        });
        const href = uploadsItem.href;
        const redirectUrl = Project.CURRENT.urlFor("data");
        setEverId(uploadsItem.node, EVERID.HEADER.DATA_TRANSFER_UPLOADS);
        Recommendations.VIEW_NEW_DOCS.getStep(1).registerDisplayer({
            node: EVERID.HEADER.DATA_TRANSFER_UPLOADS,
            placement: [PopoverPlacement.LEFT],
            redirectNext: !Util.onUploadsPage() ? redirectUrl : undefined,
            onActivate: () => {
                headerIcons.dataTransferMenu.open();
                uploadsItem.node.removeAttribute("href");
            },
            onRecommendationClose: () => (uploadsItem.href = href),
            nextFunctionInverse: () => headerIcons.dataTransferMenu.close(),
            alwaysRunNextFunction: true,
        });
    }
}

function initializeCreateFolderRecommendation() {
    setEverId(Dom.byId("nav-home"), EVERID.HEADER.PROJECT_HOME);
    Recommendations.CREATE_FOLDER.getStep(0).registerDisplayer({});
    Recommendations.CREATE_FOLDER.getStep(1).registerDisplayer({
        node: EVERID.HEADER.PROJECT_HOME,
        placement: [PopoverPlacement.BOTTOM],
        shouldSkip: Util.onHomePage,
        redirectNext: Project.CURRENT.urlFor("home"),
    });
}

function makeLinkFocusable(navButton: HTMLElement) {
    const focusDiv = makeFocusable(navButton, "focus-with-space-style");
    focusDiv.registerDestroyable(
        Input.fireCallbackOnKey(focusDiv.node, [Input.ENTER], (e) => {
            e.stopPropagation();
            navButton.click();
        }),
    );
}

function buildUserMenu() {
    const userNav = document.getElementById("nav-user");
    if (!userNav) {
        return;
    } else if (!User.me) {
        Dom.hide(userNav);
        return;
    }

    const userMenuItems = [
        {
            label: "Account Settings",
            icon: "user-20",
            href: "/profile.do",
            onClick: () => gaNavEvent("Account Settings"),
        },
        {
            label: "Log Out",
            id: "logout",
            icon: "logout-20",
            onClick: logout,
        },
    ];

    if (Is.defined(User.me.impersonatorUsername)) {
        userMenuItems.splice(1, 0, {
            label: "Stop Impersonating",
            id: "stop",
            icon: "impersonate-20",
            onClick: () => {
                gaNavEvent("Stop Impersonating");
                Impersonate.stopImpersonating();
            },
        });
    }

    const usernameDiv = Dom.div(
        { id: "nav-username", class: "user-header-name semi-bold" },
        User.me.displayName(),
    );

    const userMenuHeader = Dom.div(
        { class: "user-header" },
        UI.userBadge(User.me, ColorTokens.USER_BADGE_PRIMARY, "large circle condensed-bold"),
        Dom.div(
            { class: "user-header-details" },
            usernameDiv,
            Dom.div({ class: "user-header-email" }, User.me.email),
        ),
    );

    Dom.setContent(
        userNav,
        UI.userBadge(User.me, ColorTokens.USER_BADGE_PRIMARY, "small circle condensed-bold"),
    );

    new PopoverMenuNode(
        {
            orient: ["below-centered"],
            dialogContent: userMenuHeader,
            menuItems: userMenuItems,
            tooltip: "Your Account",
            onOpen: () => Dom.setContent(usernameDiv, User.me.displayName()),
            makeFocusable: true,
            focusStyling: "focus-with-space-style",
        },
        userNav,
    );
}

function buildDataTransferMenu(
    perms: HeaderPerms.Permissions,
    legalHoldsReadOnlyAndEmpty: boolean,
): PopoverMenuNode {
    const dataMenuItems: PopoverMenuNode.Item[] = [
        {
            label: "Uploads",
            id: "uploads",
            icon: "upload-20",
            href: Project.CURRENT.urlFor("data"),
            disabled: HeaderPerms.shouldDisableUploadsIcon(perms),
        },
    ];
    if (!Eca.inContext() && Project.CURRENT.productionMode) {
        dataMenuItems.push({
            label: "Productions",
            id: "productions",
            icon: "send-20",
            disabled: HeaderPerms.shouldDisableProductionsIcon(perms),
            href: Project.CURRENT.urlFor("data", { tab: "productions" }),
        });
    }
    if (SbFree.inContext()) {
        dataMenuItems.push({
            label: "Exports",
            id: "exports",
            icon: "external-link-20",
            disabled: Project.CURRENT.suspended,
            href: Project.CURRENT.urlFor("data", { tab: "exports" }),
        });
    } else {
        let disabled: boolean;
        let disabledTooltip: HTMLElement | string;
        if (
            Base.get(
                Organization,
                Project.CURRENT.owningOrganizationId,
            ).isTheUnassignedDatabasesOrg()
        ) {
            disabled = true;
            const supportLink = Dom.a(
                {
                    href: `mailto:${JSP_PARAMS.Help.supportEmail}`,
                    rel: "noopener noreferrer",
                    target: "_blank",
                },
                JSP_PARAMS.Help.supportEmail,
            );
            disabledTooltip = Dom.span(
                "Legal Holds is disabled in databases without an organization. Contact ",
                supportLink,
                " if you're interested in having an organization.",
            );
        } else if (legalHoldsReadOnlyAndEmpty) {
            disabled = legalHoldsReadOnlyAndEmpty;
            disabledTooltip =
                "Organization Admins or Legal Holds Organization Admins "
                + "have not added any legal holds to this database yet";
        } else {
            disabled = HeaderPerms.shouldDisableLegalHoldsIcon(perms);
        }
        dataMenuItems.unshift({
            label: "Legal Holds",
            id: "legal-holds",
            icon: "legal-holds-20",
            href: Project.CURRENT.urlFor("legalHold"),
            disabled,
            disabledTooltip,
        });
    }
    return buildNavMenu(
        document.getElementById("nav-data-transfer"),
        "Data Transfer",
        dataMenuItems,
    );
}

export const PREDICTION_WIZARD_TAB_ID = "prediction-wizard-tab";

function generatePcTab(perms) {
    let pcTab = null;
    const pcModels = Base.get(PredictionModel);

    if (pcModels.length > 0) {
        pcTab = "model" + pcModels[0].id;
    } else if (perms.canCreatePC) {
        pcTab = PREDICTION_WIZARD_TAB_ID;
    }
    return pcTab;
}

function buildAnalyticsMenu(perms: HeaderPerms.Permissions) {
    const pcTab = generatePcTab(perms);
    // We need to use Project.CURRENT.url() so the URLs will work when
    // the user is on profile.do, which does not have a project id in its URL.
    const analyticsMenuItems = [
        {
            label: "Data Visualizer",
            id: "datavis",
            icon: "bar-graph-20",
            onClick: () => {
                gaNavEvent("Data Visualizer");
                Rest.post(Project.CURRENT.url("search/getSearchForEql.rest"), {
                    eql: applyDefaultSearchGroupingToEql(
                        new Property.AllDocs(null, "allDocs"),
                    ).toString(),
                }).then(
                    (search: { id: number }) => {
                        const allDocsDatavis = `search.do#id=${search.id}&view=datavis`;
                        location.href = Project.CURRENT.url(allDocsDatavis);
                    },
                    () => {
                        // If an error is returned, search isn't accessible.
                        // Send the user to search.do so they will see an error page.
                        location.href = Project.CURRENT.url("search.do");
                    },
                );
            },
            disabled: Project.CURRENT.suspended,
        },
        {
            label: "Search Term Reports",
            id: "searchTermReport",
            icon: "file-text-20",
            href: Project.CURRENT.urlFor("searchTermReport"),
            disabled: HeaderPerms.shouldDisableSTRIcon(perms),
        },
    ];
    const analyticsHref = pcTab ? Project.CURRENT.urlFor("analytics", { tab: pcTab }) : undefined;

    if (!Eca.inContext() && analyticsHref) {
        analyticsMenuItems.push({
            label: "Predictive Coding",
            id: "predictiveCoding",
            icon: "filter-20",
            href: analyticsHref,
            disabled: HeaderPerms.shouldDisablePredictiveCodingIcon(perms, pcTab),
        });
    }
    analyticsMenuItems.push({
        label: "Document Clustering",
        id: "documentClustering",
        icon: "chart-bubble-20",
        href: Project.CURRENT.urlFor("clustering"),
        disabled: HeaderPerms.shouldDisableClusteringIcon(perms),
    });

    const tooltipMenu = buildNavMenu(
        document.getElementById("nav-analytics"),
        "Document Analytics",
        analyticsMenuItems,
    );

    setEverId(tooltipMenu.getNode(), EVERID.HEADER.ANALYTICS_ICON);

    Recommendations.CREATE_STR.getStep(0).registerDisplayer({
        node: EVERID.HEADER.ANALYTICS_ICON,
        placement: [PopoverPlacement.BOTTOM],
        nextFunction: () => tooltipMenu.open(),
        shouldSkip: Util.onSearchTermReportPage,
    });
    const menuNode = tooltipMenu.getItem("searchTermReport")?.node as HTMLAnchorElement;
    const href = pcTab && Project.CURRENT.urlFor("searchTermReport");

    setEverId(menuNode, EVERID.HEADER.SEARCH_TERM_REPORT_ICON);
    menuNode
        && Recommendations.CREATE_STR.getStep(1).registerDisplayer({
            node: EVERID.HEADER.SEARCH_TERM_REPORT_ICON,
            placement: [PopoverPlacement.LEFT],
            redirectNext: href,
            onActivate: () => {
                tooltipMenu.open();
                menuNode.removeAttribute("href");
                tooltipMenu.setDisableFocus(true);
            },
            onRecommendationClose: () => {
                menuNode.href = href;
                tooltipMenu.setDisableFocus(false);
            },
            shouldSkip: Util.onSearchTermReportPage,
        });
    if (!Eca.inContext()) {
        // Register displayer only if headerNode is visible to the user
        Recommendations.CHECK_OUT_PC.getStep(0).registerDisplayer({
            node: EVERID.HEADER.ANALYTICS_ICON,
            placement: [PopoverPlacement.BOTTOM],
            nextFunction: () => tooltipMenu.open(),
            nextFunctionInverse: () => tooltipMenu.close(),
            shouldSkip: Util.onAnalyticsPage,
        });
        const menuNode = tooltipMenu.getItem("predictiveCoding")?.node as HTMLAnchorElement;
        if (menuNode) {
            setEverId(menuNode, EVERID.HEADER.ANALYTICS_POPOVER_PREDICTIVE_CODING);
            Recommendations.CHECK_OUT_PC.getStep(1).registerDisplayer({
                node: EVERID.HEADER.ANALYTICS_POPOVER_PREDICTIVE_CODING,
                placement: [PopoverPlacement.LEFT],
                redirectNext: analyticsHref,
                onActivate: () => {
                    tooltipMenu.open();
                    tooltipMenu.getNode().removeAttribute("href");
                    tooltipMenu.setDisableFocus(true);
                },
                onRecommendationClose: () => {
                    menuNode.href = analyticsHref;
                    tooltipMenu.setDisableFocus(false);
                },
                shouldSkip: Util.onAnalyticsPage,
            });
        }
    }

    initializeClusteringRecommendation(tooltipMenu);
    return tooltipMenu;
}

function initializeClusteringRecommendation(tooltipMenu: PopoverMenuNode): void {
    Recommendations.RECOMMEND_TO_CLUSTER.getStep(0).registerDisplayer({
        node: EVERID.HEADER.ANALYTICS_ICON,
        placement: [PopoverPlacement.BOTTOM],
        onActivate: () => tooltipMenu.setDisabled(true),
        nextFunction: () => {
            if (!tooltipMenu.isOpen()) {
                tooltipMenu.open();
            }
        },
        nextFunctionInverse: () => tooltipMenu.close(),
        shouldSkip: Util.onClusteringPage,
    });
    const menuItem = tooltipMenu.getItem("documentClustering");
    if (!menuItem) {
        throw new Error("Expected the clustering menu icon to exist.");
    }
    setEverId(menuItem.node, EVERID.HEADER.ANALYTICS_POPOVER_DOCUMENT_CLUSTERING);
    const clusteringMenuItemHref = menuItem.href;
    Recommendations.RECOMMEND_TO_CLUSTER.getStep(1).registerDisplayer({
        node: EVERID.HEADER.ANALYTICS_POPOVER_DOCUMENT_CLUSTERING,
        placement: [PopoverPlacement.LEFT],
        redirectNext: Project.CURRENT.urlFor("clustering"),
        onActivate: () => {
            tooltipMenu.setDisabled(false);
            // We have to remove the href otherwise we may navigate to the clustering page
            // before finishing the call to RecommendationStep#redirectToNextStep (which would lead
            // to the recommendation not appearing when the user gets to that page).
            menuItem.node.removeAttribute("href");
            tooltipMenu.setDisableFocus(true);
            if (!tooltipMenu.isOpen()) {
                tooltipMenu.open();
            }
        },
        onRecommendationClose: () => {
            menuItem.node.setAttribute("href", clusteringMenuItemHref);
            tooltipMenu.setDisableFocus(false);
        },
        shouldSkip: Util.onClusteringPage,
    });
}

function buildStorybuilderMenu() {
    if (Eca.inContext()) {
        return;
    }

    function chronUrl(chron: Chronology) {
        return Project.CURRENT.urlFor("chron", { chronId: chron.id });
    }
    function gaChronEvent(chron: Chronology) {
        return gaNavEvent("Chronology", `${chron.id}`);
    }
    function chronMenuItemsId(chron: Chronology) {
        return "chron/0" + chron.id;
    }
    function makeChronMenuItem(chronology: Chronology) {
        return {
            label: chronology.display(),
            id: chronMenuItemsId(chronology),
            icon: "story-feather-20",
            href: chronUrl(chronology),
            onClick: () => gaChronEvent(chronology),
        };
    }
    function getUserVisibleChrons() {
        return Base.get(Chronology).filter((c) => c.userVisible);
    }
    let chrons = getUserVisibleChrons();
    const chronMenuItems = chrons.map((c) => makeChronMenuItem(c));
    const chronLink = document.getElementById("nav-storybuilder") as HTMLAnchorElement | null;
    const chronMenuContainer = document.getElementById("nav-storybuilder-menu");

    const chronMenu = buildNavMenu(chronMenuContainer, "Storybuilder", chronMenuItems);
    headerIcons.sbMenu = chronMenu;
    headerIcons.sbMenuIds = chrons.map((c) => chronMenuItemsId(c));

    // Retrieves the current list of user-visible chrons from Base
    // and shows non-hidden chrons in the navigation menu.

    let multipleChrons = false;
    let chronHref = "";
    let chronItem = undefined;
    const setShownNavChrons = () => {
        chrons = getUserVisibleChrons();
        let shownChrons = 0;
        chrons.forEach((c) => {
            if (c.hidden) {
                chronMenu.showItem(chronMenuItemsId(c), false);
            } else {
                if (!chronItem) {
                    chronItem = chronMenu.getItem(chronMenuItemsId(c));
                    setEverId(chronItem.node, EVERID.HEADER.CHRON_MENU_ITEM);
                }
                shownChrons += 1;
                chronMenu.showItem(chronMenuItemsId(c), true);

                if (shownChrons === 1 && !!chronLink) {
                    chronLink.href = chronUrl(c);
                    chronLink.onclick = () => gaChronEvent(c);
                    highlightCurrentPage(chronLink);
                }
            }
        });

        if (shownChrons > 1) {
            chronMenuContainer && Dom.show(chronMenuContainer);
            chronLink && Dom.hide(chronLink);
            const storybuilderDiv = Dom.div();
            Dom.place(storybuilderDiv, Dom.byId("nav-storybuilder-menu"));
            setEverId(storybuilderDiv, EVERID.HEADER.STORYBUILDER_ICON);
        } else {
            chronMenuContainer && Dom.hide(chronMenuContainer);
            chronLink && Dom.show(chronLink);
            chronLink && Dom.toggleClass(chronLink, "disabled", shownChrons === 0);
            setEverId(chronLink, EVERID.HEADER.STORYBUILDER_ICON);
        }

        multipleChrons = shownChrons <= 1;
        chronHref = chronLink.href;
    };
    setShownNavChrons();
    function initStorybuilderRecommendations(): void {
        Recommendations.PEOPLE_PROFILES.getStep(0).reregisterDisplayer({
            node: EVERID.HEADER.STORYBUILDER_ICON,
            placement: [PopoverPlacement.BOTTOM],
            redirectNext: multipleChrons ? chronHref : undefined,
            nextFunction: () => {
                if (multipleChrons) {
                    chronMenu.open();
                    chronMenu.focus();
                }
            },
            onActivate: () => {
                chronLink.removeAttribute("href");
            },
            onRecommendationClose: () => {
                chronLink.href = chronHref;
            },
            shouldSkip: Util.onChronologyPage,
        });
        Recommendations.PEOPLE_PROFILES.getStep(1).reregisterDisplayer({
            node: EVERID.HEADER.CHRON_MENU_ITEM,
            placement: [PopoverPlacement.LEFT],
            redirectNext: !multipleChrons ? chronItem.href : undefined,
            shouldSkip: () => multipleChrons || Util.onChronologyPage(),
            onActivate: () => {
                if (!multipleChrons) {
                    chronMenu.open();
                    chronMenu.setDisableFocus(true);
                    chronItem.node.removeAttribute("href");
                }
            },
            onRecommendationClose: () => {
                if (!multipleChrons) {
                    chronItem.node.setAttribute("href", chronItem.href);
                    chronMenu.setDisableFocus(false);
                }
            },
        });

        Recommendations.STORY_LABELS_EVENTS.getStep(0).registerDisplayer({
            node: EVERID.HEADER.STORYBUILDER_ICON,
            placement: [PopoverPlacement.BOTTOM],
            redirectNext: multipleChrons ? chronHref : undefined,
            nextFunction: () => {
                if (multipleChrons) {
                    chronMenu.open();
                    chronMenu.focus();
                }
            },
            onActivate: () => {
                chronLink.removeAttribute("href");
            },
            onRecommendationClose: () => {
                chronLink.href = chronHref;
            },
            shouldSkip: Util.onChronologyPage,
        });
        Recommendations.STORY_LABELS_EVENTS.getStep(1).registerDisplayer({
            node: EVERID.HEADER.CHRON_MENU_ITEM,
            placement: [PopoverPlacement.LEFT],
            redirectNext: !multipleChrons ? chronItem.href : undefined,
            shouldSkip: () => multipleChrons || Util.onChronologyPage(),
            onActivate: () => {
                if (!multipleChrons) {
                    chronMenu.open();
                    chronMenu.setDisableFocus(true);
                    chronItem.node.removeAttribute("href");
                }
            },
            onRecommendationClose: () => {
                if (!multipleChrons) {
                    chronItem.node.setAttribute("href", chronItem.href);
                    chronMenu.setDisableFocus(false);
                }
            },
        });
        Recommendations.VIEW_DEPOSITION.getStep(0).registerDisplayer({
            node: EVERID.HEADER.STORYBUILDER_ICON,
            placement: [PopoverPlacement.BOTTOM],
            redirectNext: multipleChrons ? chronHref : undefined,
            nextFunction: () => {
                if (multipleChrons) {
                    chronMenu.open();
                    chronMenu.focus();
                }
            },
            onActivate: () => {
                chronLink.removeAttribute("href");
            },
            onRecommendationClose: () => {
                chronLink.href = chronHref;
            },
        });
        Recommendations.VIEW_DEPOSITION.getStep(1).registerDisplayer({
            node: EVERID.HEADER.CHRON_MENU_ITEM,
            placement: [PopoverPlacement.LEFT],
            redirectNext: !multipleChrons ? chronItem.href : undefined,
            shouldSkip: () => multipleChrons,
            onActivate: () => {
                if (!multipleChrons) {
                    chronMenu.setDisableFocus(true);
                    chronMenu.open();
                    chronItem.node.removeAttribute("href");
                }
            },
            onRecommendationClose: () => {
                if (!multipleChrons) {
                    chronMenu.setDisableFocus(false);
                    chronItem.node.setAttribute("href", chronItem.href);
                }
            },
        });
    }
    if (getUserVisibleChrons().length !== 0) {
        initStorybuilderRecommendations();
    }

    Base.subscribe(Chronology, (updated: Chronology[]) => {
        // If it's a new chron, add it to the menu and push it to the local array of chrons.
        updated.forEach((c) => {
            if (!Is.defined(chronMenu.getItem(chronMenuItemsId(c))) && c.userVisible) {
                chronMenu.add(makeChronMenuItem(c), "STORYBUILDER");
            }
        });
        setShownNavChrons();
    });
}

function buildAdminMenu(perms: HeaderPerms.Permissions) {
    const adminMenuItems = [
        {
            label: "Project Settings",
            id: "settings",
            icon: "settings-20",
            href: Project.CURRENT.urlFor("settings"),
            disabled: HeaderPerms.shouldDisableAdminIcons(perms),
        },
        {
            label: "Database Settings",
            id: "databaseSettings",
            icon: "database-settings-20",
            href: Project.CURRENT.urlFor("database"),
            disabled: !perms.canDbAdmin,
        },
        {
            label: "User Activity",
            id: "userActivity",
            icon: "activity-20",
            href: Project.CURRENT.urlFor("analytics"),
            disabled: HeaderPerms.shouldDisableAdminIcons(perms),
        },
    ];
    if (!Eca.inContext()) {
        adminMenuItems.push({
            label: "Assignment Groups",
            id: "assignments",
            icon: "assignments-list-20",
            href: Project.CURRENT.urlFor("assignments"),
            disabled: HeaderPerms.shouldDisableAssignmentGroupsIcon(perms),
        });
    }
    adminMenuItems.push({
        label: "Project Analytics",
        id: "analytics",
        icon: "project-analytics-20",
        href: Project.CURRENT.urlFor("analytics", {
            tab: Eca.inContext() ? "projectSize" : "overview",
        }),
        disabled: HeaderPerms.shouldDisableAnalyticsIcon(perms),
    });

    return buildNavMenu(document.getElementById("nav-admin"), "Project Management", adminMenuItems);
}

function buildNavMenu(
    el: HTMLElement | null,
    title: string,
    menuItems: PopoverMenuNode.Item[],
    onMenuOpen: (() => void) | null = null,
): PopoverMenuNode {
    menuItems.forEach(
        (item) =>
            (item.disabledTooltip =
                item.disabledTooltip || "You do not have permission to access this page"),
    );
    return new PopoverMenuNode(
        {
            orient: ["below"],
            sections: [
                {
                    header: title.toUpperCase(),
                    menuItems,
                },
            ],
            tooltip: title,
            onClick: (item) => {
                gaNavEvent(item.label.toString());
            },
            onOpen: () => {
                onMenuOpen?.();
            },
            class: "nav-menu",
            mirrorTooltip: true,
            makeFocusable: true,
            focusStyling: "focus-with-space-style",
            propagateClickEvt: true,
        },
        el,
    );
}

function highlightCurrentPage(el: HTMLAnchorElement) {
    const pageName = (str: string) => Str.substrBetweenLast("/", ".do", str);
    const isDatavisView = location.href.includes("view=datavis");
    if (pageName(el.href) === pageName(location.href)) {
        if (!isDatavisView) {
            Dom.setAttr(el, "aria-current", "page");
        } else if (Dom.hasAttr(el, "aria-current")) {
            Dom.removeAttr(el, "aria-current");
        }
    }
}

export function refreshSearchIconHighlight() {
    Dom.ifNodeExists("nav-search", (n) => {
        highlightCurrentPage(n as HTMLAnchorElement);
    });
}

function gaNavEvent(action: string, label?: string, overrideHref = false) {
    ga_event("Project Nav Icon", action, label);
    return !overrideHref;
}

function initWalkMe(perms: HeaderPerms.Permissions) {
    WalkMe.init();

    const walkmePerms = [
        [true, "hidden"],
        [perms.canDbAdmin, "walkme-can-database-admin"],
        [perms.canProjectAdmin, "walkme-can-project-admin"],
        [perms.canAdminAGs, "walkme-can-admin-assignment-groups"],
        [perms.canCreateAGs, "walkme-can-create-assignment-groups"],
        [perms.canReceiveAGs, "walkme-can-receive-assignment-groups"],
        [perms.canAdminSTRs, "walkme-can-admin-search-term-reports"],
        [perms.canCreateSTRs, "walkme-can-create-search-term-reports"],
        [perms.canReceiveSTRs, "walkme-can-receive-search-term-reports"],
        [perms.canAnalytics, "walkme-can-analytics"],
        [perms.canAdminPC, "walkme-can-admin-pc"],
        [perms.canCreatePC, "walkme-can-create-pc"],
        [perms.canReceivePC, "walkme-can-receive-pc"],
        [perms.canUpload, "walkme-can-upload"],
        [perms.canAdminProductions, "walkme-can-admin-productions"],
        [perms.canShareProductions, "walkme-can-share-productions"],
        [perms.canAdminSB, "walkme-can-admin-storybuilder"],
        [perms.canCreateSB, "walkme-can-create-storybuilder"],
        [perms.canReceiveSB, "walkme-can-receive-storybuilder"],
    ];
    const walkMeClasses = walkmePerms.filter((item) => item[0]).map((item) => item[1]);

    // Create a hidden div with all our walkme classes.
    Dom.create("div", { class: walkMeClasses.join(" ") }, "header");
}

function initSmartOnboarding() {
    RecommendationInit.init();
}

function initDevBanner(version: string) {
    // Allow the "This is a development server" banner to be closed for a set period of time.
    const devBanner = Dom.byId("not-live-banner");
    const hideBannerKey = "hide_banner";
    const showReactRoots = "show_react_roots";
    const reactHighlightStyleElementId = "react-dev-highlight-id";
    const reactHighlightStyle = `
        .react-root, .react-root *, .bb-component-content, .bb-component-content * {
            background-color: #DDAAAA !important;
        }
    `;
    const showDevBanner = function () {
        ls.removeItem(hideBannerKey);
        Dom.show(devBanner);
    };
    const hideDevBanner = function (timePeriod: number) {
        setTimeout(() => {
            showDevBanner();
        }, timePeriod);
        Dom.hide(devBanner);
    };
    const now = Date.now();
    const ts = ls.getItem(hideBannerKey);
    const toggleReactHighlightsButton = document.getElementById("toggle-react-highlights");
    if (toggleReactHighlightsButton) {
        const highlightReactRoots = (show: boolean) => {
            toggleReactHighlightsButton.textContent = `${show ? "Hide" : "Show"} React`;
            const styleElement = document.getElementById(reactHighlightStyleElementId);
            if (show) {
                if (styleElement) {
                    // style already exists.
                    return;
                }
                const newStyleSheet = document.createElement("style");
                newStyleSheet.id = reactHighlightStyleElementId;
                newStyleSheet.innerHTML = reactHighlightStyle;
                document.body.appendChild(newStyleSheet);
            } else {
                if (!styleElement) {
                    // style already removed.
                    return;
                }
                styleElement.parentNode?.removeChild(styleElement);
            }
        };
        let show = !!ls.getItem(showReactRoots);
        highlightReactRoots(show);
        toggleReactHighlightsButton.addEventListener("click", (event) => {
            event.stopPropagation();
            show = !show;
            show ? ls.setItem(showReactRoots, "show") : ls.removeItem(showReactRoots);
            highlightReactRoots(show);
        });
    }
    if (ts == null) {
        showDevBanner();
    } else {
        const timestamp = parseFloat(ts);
        if (!isNaN(timestamp) && timestamp - now > 0) {
            hideDevBanner(timestamp - now);
        } else {
            showDevBanner();
        }
    }
    devBanner.addEventListener("click", () => {
        const errorBanner = document.getElementById("error-banner");
        if (errorBanner && !Dom.isHidden(errorBanner)) {
            // If the error banner has been shown, there was a Javascript error on the page.
            // We don't want to give the user the "Hide dev banner" dialog since they'll want
            // to see the error message on click. This is handled by vanilla javascript in
            // header.jsp rather than here because it's possible that this listener doesn't
            // even get added in the event of a very early JS error.
            return;
        }
        const dialog = new InputDialog({
            title: "Hide development banner",
            prompt: "How many minutes do you want to hide this banner?",
            submitText: "Hide",
            caneclText: "Cancel",
            value: "5",
            onSubmit: (rawVal: string) => {
                const val = parseFloat(rawVal);
                if (!isNaN(val) && val > 0) {
                    ls.setItem(hideBannerKey, (now + val * C.MIN).toString());
                    hideDevBanner(val * C.MIN);
                }
                dialog.hide();
            },
        });
        dialog.show();
    });
}

export function shouldShowSuperUserWarning(accessRestrictions: AccessRestrictions): boolean {
    return accessRestrictions.cjis || accessRestrictions.sensitive;
}

function superUserWarningReason(accessRestrictions: AccessRestrictions): SuperuserWarningReason {
    return accessRestrictions.cjis
        ? new CjisSuperuserWarning()
        : new SensitiveSuperuserWarning(accessRestrictions.orgName);
}

/** This function assumes that User.me is non-null! */
function addSuperUserWarning(accessRestrictions: AccessRestrictions) {
    if (!User.me) {
        Bugsnag.notify(Error("Header.addSuperUserWarning called in a context with no User.me"));
        return;
    }
    // banner
    const superUserAccessWarning = Dom.byId("superuser-access-warning-banner");
    Dom.show(superUserAccessWarning);
    Dom.addContent(
        superUserAccessWarning,
        Dom.span({ class: "icon icon_alert-triangle-20", style: "margin-right: 8px" }),
        Dom.b({ style: "margin-right: 4px" }, "Warning: "),
        accessRestrictions.orgName + ` has special access requirements.`,
    );
    // popup
    if (shouldShowPopUpWarningForUrl(document.referrer)) {
        const onCancel = () => {
            if (Is.defined(User.me.impersonatorUsername)) {
                window.location.href = "/profile.do";
            } else {
                window.location.href = "/";
            }
            return false;
        };
        withSuperUserAccessPopUp(accessRestrictions, null, onCancel);
    }
}

export function withSuperUserAccessPopUp(
    accessRestrictions: AccessRestrictions,
    onSubmit?: () => void,
    onCancel?: () => boolean,
): void {
    if (!shouldShowSuperUserWarning(accessRestrictions)) {
        onSubmit && onSubmit();
        return;
    }
    const reason = superUserWarningReason(accessRestrictions);
    QueryDialog.create({
        title: reason.getTitle(),
        prompt: reason.getBody(),
        classes: "superuser-access-dialog",
        submitText: "Proceed",
        submitIsSafe: false,
        closable: false,
        confirmationContent: reason.getConfirmationContent(),
        destroyOnClose: true,
        onSubmit: () => {
            onSubmit && onSubmit();
            return true;
        },
        onCancel: onCancel,
    });
}

abstract class SuperuserWarningReason {
    abstract getTitle(): string;
    abstract getBody(): Dom.Content;
    abstract getConfirmationContent(): string | undefined;
}

class SensitiveSuperuserWarning extends SuperuserWarningReason {
    constructor(private orgName: string) {
        super();
    }
    getTitle() {
        return "Database with special requirements";
    }
    getBody() {
        return Dom.div(
            { style: { width: "450px" } },
            ` You are attempting to access a database with special requirements owned by `,
            Dom.b(this.orgName),
            ".",
            Dom.p(
                {
                    style: {
                        "margin-bottom": "0px",
                    },
                },
                "Make sure you are fulfilling ",
                Dom.b(this.orgName + "'s"),
                " requirements before you access this database.",
            ),
        );
    }
    getConfirmationContent(): undefined {
        return undefined;
    }
}

class CjisSuperuserWarning extends SuperuserWarningReason {
    constructor() {
        super();
    }
    getTitle() {
        return "Special data access requirements";
    }
    getBody() {
        return Dom.span(
            "You are attempting to access a project that belongs to a database with CJIS "
                + "data. Please confirm that you are authorized to access CJIS data before proceeding.",
        );
    }
    getConfirmationContent() {
        return "I am authorized to access CJIS data";
    }
}

export function isOnSuperuserPage(): boolean {
    return !Project.CURRENT && User.me && User.me.isSuperuserOrImpersonating();
}

function usedForwardBackBtns() {
    // PerformanceNavigation is deprecated, but PerformanceNavigationTiming is still
    // experimental and neither supported in Safari <= 14 nor IE.
    return (
        window.performance
        && (window.performance
            .getEntriesByType("navigation")
            .map((nav: any) => nav.type)
            .indexOf("back_forward") >= 0
            || (window.performance.navigation
                && window.performance.navigation.type
                    === window.performance.navigation.TYPE_BACK_FORWARD))
    );
}

function refreshedPage() {
    return (
        window.performance
        && (window.performance
            .getEntriesByType("navigation")
            .map((nav: any) => nav.type)
            .indexOf("reload") >= 0
            || (window.performance.navigation
                && window.performance.navigation.type
                    === window.performance.navigation.TYPE_RELOAD))
    );
}

function shouldShowPopUpWarningForUrl(urlString: string) {
    if (!urlString) {
        return true;
    }
    // show popup if using browser back/forward buttons
    if (usedForwardBackBtns()) {
        return true;
    }
    const prevURL = new URL(urlString);
    // check matching hostname
    if (prevURL.hostname !== window.location.hostname || prevURL.pathname === "") {
        return true;
    }
    // when accessing a project's home page or settings page from the Projects & Users superuser
    // page, the popup is shown before the page is loaded, so it doesn't need to be shown a second
    // time
    if (
        prevURL.pathname === "/superuser.do"
        && (Str.endsWith(window.location.pathname, "home.do")
            || Str.endsWith(window.location.pathname, "settings.do"))
    ) {
        return false;
    }
    // check matching project id
    const prevArr = prevURL.pathname.split("/");
    return !(prevArr.length > 1 && parseInt(prevArr[1]) === Project.CURRENT.id);
}

export function updateIcons(perms?: HeaderPerms.Permissions) {
    const suspended = HeaderPerms.displayProjectAsSuspended();
    const adminsSuspended = Project.CURRENT.isImpactedByAdminSuspension();
    if (!Is.defined(perms)) {
        perms = HeaderPerms.checkPermissions();
    }
    const pcTab = generatePcTab(perms);
    const noVisibleChrons =
        Base.get(Chronology).filter((c) => c.userVisible && !c.hidden).length === 0;
    const navDataTransfer = document.getElementById("nav-data-transfer");
    const navAnalytics = document.getElementById("nav-analytics");
    const navStorybuilder = document.getElementById("nav-storybuilder");
    const navStorybuilderMenu = document.getElementById("nav-storybuilder-menu");
    Dom.toggleClass(
        Dom.byId("nav-home"),
        "disabled display-as-disabled",
        suspended || adminsSuspended,
    );
    Dom.toggleClass(
        Dom.byId("nav-search"),
        "disabled display-as-disabled",
        suspended || adminsSuspended,
    );
    navStorybuilder
        && Dom.toggleClass(
            navStorybuilder,
            "disabled display-as-disabled disable-clicks",
            suspended || noVisibleChrons || adminsSuspended,
        );
    navStorybuilderMenu
        && Dom.toggleClass(
            navStorybuilderMenu,
            "disabled display-as-disabled disable-clicks",
            suspended || noVisibleChrons || adminsSuspended,
        );
    navAnalytics
        && Dom.toggleClass(
            navAnalytics,
            "disabled display-as-disabled",
            suspended || adminsSuspended,
        );
    navDataTransfer
        && Dom.toggleClass(
            navDataTransfer,
            "disabled display-as-disabled",
            suspended || adminsSuspended,
        );
    if (adminsSuspended) {
        navAnalytics && Dom.addClass(navAnalytics, "disable-clicks");
        navDataTransfer && Dom.addClass(navDataTransfer, "disable-clicks");
    }
    Dom.toggleClass(
        Dom.byId("nav-admin"),
        "disabled display-as-disabled disable-clicks",
        adminsSuspended,
    );
    Dom.toggleClass(
        Dom.byId("nav-messages"),
        "disabled display-as-disabled disable-clicks",
        adminsSuspended,
    );
    headerIcons.adminMenu.setItemDisabled(
        "settings",
        HeaderPerms.shouldDisableCodesAdminIcons(perms),
    );
    headerIcons.adminMenu.setItemDisabled(
        "userActivity",
        HeaderPerms.shouldDisableAdminIcons(perms),
    );
    headerIcons.adminMenu.setItemDisabled(
        "assignments",
        HeaderPerms.shouldDisableAssignmentGroupsIcon(perms),
    );
    headerIcons.adminMenu.setItemDisabled(
        "analytics",
        HeaderPerms.shouldDisableAnalyticsIcon(perms),
    );
    headerIcons.dataTransferMenu.setItemDisabled(
        "uploads",
        HeaderPerms.shouldDisableUploadsIcon(perms),
    );
    headerIcons.dataTransferMenu.setItemDisabled(
        "productions",
        HeaderPerms.shouldDisableProductionsIcon(perms),
    );
    // Legal holds is not handled here because it has more complicated disabling logic,
    // and is handled separately in buildDataTransferMenu
    headerIcons.dataTransferMenu.setItemDisabled("exports", suspended);
    headerIcons.analyticsMenu.setItemDisabled("datavis", suspended);
    headerIcons.analyticsMenu.setItemDisabled(
        "searchTermReport",
        HeaderPerms.shouldDisableSTRIcon(perms),
    );
    headerIcons.analyticsMenu.setItemDisabled(
        "predictiveCoding",
        HeaderPerms.shouldDisablePredictiveCodingIcon(perms, pcTab),
    );
    headerIcons.analyticsMenu.setItemDisabled(
        "documentClustering",
        HeaderPerms.shouldDisableClusteringIcon(perms),
    );
    for (const sbMenuKey in headerIcons.sbMenuIds) {
        headerIcons.sbMenu.setItemDisabled(sbMenuKey, suspended || noVisibleChrons);
    }
    if (suspended || adminsSuspended) {
        Dom.removeAttr(Dom.byId("nav-home"), "href");
        Dom.removeAttr(Dom.byId("nav-search"), "href");
        Dom.setAttr(Dom.byId("header-logo-link"), "href", "/");
    } else {
        Dom.setAttr(Dom.byId("nav-home"), "href", `/${Project.CURRENT.id}/home.do`);
        Dom.setAttr(Dom.byId("nav-search"), "href", `/${Project.CURRENT.id}/search.do`);
        Dom.setAttr(Dom.byId("header-logo-link"), "href", `/${Project.CURRENT.id}/home.do`);
    }
}

interface headerIconMenus {
    dataTransferMenu: PopoverMenuNode;
    analyticsMenu: PopoverMenuNode;
    adminMenu: PopoverMenuNode;
    sbMenu: PopoverMenuNode;
    sbMenuIds: string[];
}
