import * as Base from "Everlaw/Base";
import * as Project from "Everlaw/Project";
import * as Rest from "Everlaw/Rest";
import * as SearchResult from "Everlaw/SearchResult";
import { onSearchPage } from "Everlaw/Util";
import { UrlHash } from "core";
import * as React from "react";
import { Dispatch, ReactNode, SetStateAction, useState } from "react";
import {
    ButtonSize,
    CommonIcon,
    DropdownMenu,
    Form,
    FormSubmitButton,
    Icon,
    InlineDropdownMenu,
    Paragraph,
    TextArea,
    useForm,
} from "design-system";

interface FormValues {
    oracleQuery: string;
}

interface OracleSubmitFormHeaderProps {
    searchId: number | undefined;
    searchEntireDatabase: boolean;
    setSearchEntireDatabase: Dispatch<SetStateAction<boolean>>;
}

function OracleSubmitFormHeader({
    searchId,
    searchEntireDatabase,
    setSearchEntireDatabase,
}: OracleSubmitFormHeaderProps): ReactNode {
    const [showDocumentSetOptions, setShowDocumentSetOptions] = useState(false);
    const entireProjectDatabaseLabel = "Entire project database";
    const documentsFromResultsTableLabel = "Documents from results table";
    const searchCount =
        Base.useStoreObject(Base.globalStore(SearchResult), searchId as SearchResult.Id)?.numResults
        ?? 0; // The '0' is included here just for safety, but should really never be visible

    return (
        <div aria-label={"Enter question or prompt"} className={"flex-horizontal margin-bottom-8"}>
            <div className={"flex-centered gap-4"}>
                <Paragraph.Bold>Ask a question</Paragraph.Bold>
                <CommonIcon.AIAssist size={16} aria-hidden={true} />
            </div>
            <div className={"flex-centered gap-8"}>
                <Paragraph.Bold>Document set</Paragraph.Bold>
                {searchId === undefined ? (
                    <Paragraph>{entireProjectDatabaseLabel}</Paragraph>
                ) : (
                    <>
                        <InlineDropdownMenu
                            disabled={false}
                            value={
                                searchEntireDatabase
                                    ? entireProjectDatabaseLabel
                                    : documentsFromResultsTableLabel
                            }
                            setShow={setShowDocumentSetOptions}
                            show={showDocumentSetOptions}
                            label={"Choose document set"}
                            className={"input-label-dropdown"}
                        >
                            <DropdownMenu.Section>
                                <DropdownMenu.Option
                                    label={entireProjectDatabaseLabel}
                                    onClick={() => {
                                        setSearchEntireDatabase(true);
                                        setShowDocumentSetOptions(false);
                                    }}
                                    selected={searchEntireDatabase}
                                />
                                {searchId && (
                                    <DropdownMenu.Option
                                        label={documentsFromResultsTableLabel}
                                        onClick={() => {
                                            setSearchEntireDatabase(false);
                                            setShowDocumentSetOptions(false);
                                        }}
                                        selected={!searchEntireDatabase}
                                    />
                                )}
                            </DropdownMenu.Section>
                        </InlineDropdownMenu>
                        {!searchEntireDatabase && (
                            <>
                                <div className={"oracle-header-vertical-rule"} />
                                <div className={"gap-4 flex-centered"}>
                                    <Icon.File />
                                    <Paragraph.Number>{searchCount}</Paragraph.Number>
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

export function OracleSubmitForm(): ReactNode {
    const searchId = onSearchPage() ? (UrlHash.get()["id"] as number) : undefined;
    const [searchEntireDatabase, setSearchEntireDatabase] = useState(searchId === undefined);
    const [hasQuerySubmissionError, setHasQuerySubmissionError] = useState(false);
    const formResult = useForm<FormValues>({
        initialValues: {
            oracleQuery: "",
        },
        onSubmit: () => {
            setHasQuerySubmissionError(false);
            Rest.post(`/${Project.getCurrentId()}/oracleQuery.rest`, {
                query: formResult.values.oracleQuery,
                searchResultId: searchEntireDatabase ? undefined : searchId,
            }).catch(() => {
                setHasQuerySubmissionError(true);
                throw new Error("Error submitting oracle query");
            });
        },
    });

    return (
        <Form onSubmit={formResult.submit} className={"oracle-form-submit"}>
            <OracleSubmitFormHeader
                searchId={searchId}
                searchEntireDatabase={searchEntireDatabase}
                setSearchEntireDatabase={setSearchEntireDatabase}
            />
            <TextArea
                placeholder={"Enter question or prompt"}
                className={"oracle-form-submit-text-area"}
                name={"Oracle query"}
                horizontal={false}
                value={formResult.values.oracleQuery}
                onBlur={(_e) => formResult.blur("oracleQuery")}
                onChange={(e) => formResult.change("oracleQuery", e.target.value)}
                label={"Ask a question"}
                hideLabel={true}
                disabled={formResult.loading}
                error={hasQuerySubmissionError}
                errorMessage={"There was an error submitting this question"}
            />
            <div className={"bb-form__footer"}>
                <details>
                    <summary>Guidelines for writing a good question</summary>
                    {/* TODO: update with Design feedback*/}
                    {/* TODO: update button stylings to be Everlaw carets?*/}
                    <Paragraph.Bold>General guidelines</Paragraph.Bold>
                    <ul className={"margin-0"}>
                        <li>Specify the output, including the length and structure.</li>
                    </ul>
                </details>
                <FormSubmitButton
                    disabled={!formResult.values.oracleQuery}
                    loading={formResult.loading}
                    size={ButtonSize.SMALL}
                    icon={<Icon.Sparkles />}
                >
                    Send
                </FormSubmitButton>
            </div>
        </Form>
    );
}
