import { Str } from "core";
import {
    Button,
    ButtonColor,
    CommonIcon,
    Confirmation,
    Counter,
    DialogSize,
    H3,
    Icon,
    IconButton,
    Link,
    NavigationBar,
    NonModal,
    Paragraph,
    Span,
    TextButton,
    Tooltip,
    useBrandedMemo,
} from "design-system";
import { LlmFeedback } from "Everlaw/LLM/LlmFeedback";
import { TextWithCitations, textWithoutCitations } from "Everlaw/Oracle/OracleCitationComponents";
import { AllRankedReferences, ReferencesUsedInAnswer } from "Everlaw/Oracle/OracleReferenceTables";
import {
    deleteInfo,
    getAllRankedFactoidsForAnswer,
    getCitationsForAnswer,
    getNumPotentiallyRelevantDocs,
    getSearchResultURL,
    OracleInfo,
    OracleQueryStep,
} from "Everlaw/Oracle/OracleUtils";
import * as Project from "Everlaw/Project";
import { displayDateTime } from "Everlaw/ProjectDateUtil";
import { addToast, ToastBoxLocation } from "Everlaw/ToastBoxManager";
import { copyPlainTextToClipboard } from "Everlaw/UI/Copyable";
import { IconSize } from "Everlaw/UI/Feedback";
import * as Perm from "Everlaw/PermissionStrings";
import * as User from "Everlaw/User";
import * as React from "react";
import { ReactNode, useEffect, useState, useRef, useId, useCallback, memo } from "react";

interface OracleCardProps {
    info: OracleInfo;
    open: boolean;
    toggleOpen: (open: boolean) => void;
}

export function OracleCard({ info, open, toggleOpen }: OracleCardProps): ReactNode {
    const GENERATING_TOOLTIP_TEXT = "Response is being generated";

    const didInit = useRef(false);
    // Open the card on submission when the card is first created
    useEffect(() => {
        if (!didInit.current) {
            didInit.current = true;
            if (info.currentStep !== OracleQueryStep.ANSWER_CONSOLIDATION) {
                toggleOpen(true);
            }
        }
    }, [info.currentStep, toggleOpen]);

    const generatingButtonIconRef = useRef<SVGSVGElement>(null);
    const generatingButtonTooltipId = useId();

    const generatingRowRef = useRef<HTMLElement>(null);
    const generatingRowTooltipId = useId();

    const isGeneratingAnswer = !info.consolidatedAnswer;

    return (
        <>
            <article
                className={
                    info.questionSubmission.hasError
                        ? "oracle-card oracle-error-card-border"
                        : "oracle-card"
                }
                aria-describedby={isGeneratingAnswer ? generatingRowTooltipId : undefined}
                ref={generatingRowRef}
            >
                {info.questionSubmission && (
                    <header className={"flex-horizontal gap-16"}>
                        <div className={"oracle-info-header-main"}>
                            <H3.Small>{info.questionSubmission.questionText}</H3.Small>
                            <HeaderInfo info={info} />
                        </div>
                        <IconButton
                            aria-label={open ? "Close answer" : "Open answer"}
                            onClick={() => toggleOpen(!open)}
                            disabled={isGeneratingAnswer}
                            aria-describedby={
                                isGeneratingAnswer ? generatingButtonTooltipId : undefined
                            }
                        >
                            {open ? (
                                <Icon.ChevronUp aria-hidden={true} ref={generatingButtonIconRef} />
                            ) : (
                                <Icon.ChevronRight
                                    aria-hidden={true}
                                    ref={generatingButtonIconRef}
                                />
                            )}
                        </IconButton>
                        {isGeneratingAnswer && (
                            <Tooltip
                                id={generatingButtonTooltipId}
                                target={generatingButtonIconRef}
                            >
                                {GENERATING_TOOLTIP_TEXT}
                            </Tooltip>
                        )}
                    </header>
                )}
                {open
                    && (info.questionSubmission.hasError ? (
                        <OracleErrorState info={info} />
                    ) : info.currentStep === OracleQueryStep.ANSWER_CONSOLIDATION ? (
                        <OracleAnswer info={info} />
                    ) : (
                        <OracleGeneratingState info={info} />
                    ))}
            </article>
            {isGeneratingAnswer && (
                <Tooltip id={generatingRowTooltipId} target={generatingRowRef}>
                    {GENERATING_TOOLTIP_TEXT}
                </Tooltip>
            )}
        </>
    );
}

interface PossiblyRelevantContentProps {
    info: OracleInfo;
    openSearchResult: (info: OracleInfo) => Promise<void>;
}

const PossiblyRelevantContent = memo(({ info, openSearchResult }: PossiblyRelevantContentProps) => {
    return (
        <div className={"oracle-potentially-relevant-search-result"}>
            <CommonIcon.Success size={20} />
            <Paragraph>
                {Str.countOf(info.searchResult?.numRelevantDocs ?? 0, "document")
                    + " were identified as potentially relevant. "}
            </Paragraph>
            <TextButton
                className={"oracle-view-in-results-table-button"}
                onClick={() => {
                    openSearchResult(info);
                }}
            >
                View in results table
            </TextButton>
        </div>
    );
});

interface OracleNonModalReferencesProps {
    info: OracleInfo;
}

enum NavigationTab {
    POSSIBLY_RELEVANT_DOCUMENTS = "POSSIBLY_RELEVANT_DOCUMENTS",
    ALL_GENERATED_FACTS = "ALL_GENERATED_FACTS",
    FACTS_USED_IN_RESPONSE = "FACTS_USED_IN_RESPONSE",
}

function OracleReferences({ info }: OracleNonModalReferencesProps): ReactNode {
    const [selectedNavigationTab, setSelectedNavigationTab] = useState<NavigationTab>(
        NavigationTab.ALL_GENERATED_FACTS,
    );

    // There is room for optimizing data fetching
    useEffect(() => {
        if (info.searchResult === undefined) {
            getNumPotentiallyRelevantDocs(info.id as number);
        }
    }, [info]);

    // There is room for optimizing data fetching
    useEffect(() => {
        if (info.allFactoids === undefined) {
            getAllRankedFactoidsForAnswer(info.id as number, info.consolidatedAnswer.answerId);
        }
    }, [info]);

    // There is room for optimizing data fetching
    useEffect(() => {
        // Although this is also done in OracleAnswer, having it also here allows this component
        // to be reused outside OracleAnswer
        if (info.answerCitations === undefined) {
            getCitationsForAnswer(info.id as number, info.consolidatedAnswer.answerId);
        }
    }, [info]);

    const openSearchResult = useCallback(async (info: OracleInfo) => {
        if (info.searchResultUrl === undefined) {
            await getSearchResultURL(info.id as number);
        }
        window.open(info.searchResultUrl);
    }, []);

    const navigationTabs = useBrandedMemo(() => {
        return [
            <NavigationBar.Tab
                key={NavigationTab.POSSIBLY_RELEVANT_DOCUMENTS}
                id={NavigationTab.POSSIBLY_RELEVANT_DOCUMENTS}
                label={"Possibly relevant documents"}
                additionalContent={
                    <>
                        <Counter hasWhiteBackground={false}>
                            {info.searchResult?.numRelevantDocs ?? 0}
                        </Counter>
                    </>
                }
            />,
            <NavigationBar.Tab
                key={NavigationTab.ALL_GENERATED_FACTS}
                id={NavigationTab.ALL_GENERATED_FACTS}
                label={"All generated facts"}
                additionalContent={
                    <>
                        <Counter hasWhiteBackground={false}>
                            {info.allFactoids?.length ?? 0}
                        </Counter>
                    </>
                }
            />,
            <NavigationBar.Tab
                key={NavigationTab.FACTS_USED_IN_RESPONSE}
                id={NavigationTab.FACTS_USED_IN_RESPONSE}
                label={"Facts used in response"}
                additionalContent={
                    <>
                        <Counter hasWhiteBackground={false}>
                            {info.answerCitations?.length ?? 0}
                        </Counter>
                    </>
                }
            />,
        ];
    }, [info.searchResult, info.allFactoids, info.answerCitations]);

    const TabContent = memo(
        ({
            selectedNavigationTab,
            info,
            openSearchResult,
        }: {
            selectedNavigationTab: NavigationTab;
            info: OracleInfo;
            openSearchResult: (info: OracleInfo) => Promise<void>;
        }) => {
            switch (selectedNavigationTab) {
                case NavigationTab.POSSIBLY_RELEVANT_DOCUMENTS:
                    return (
                        <PossiblyRelevantContent info={info} openSearchResult={openSearchResult} />
                    );
                case NavigationTab.ALL_GENERATED_FACTS:
                    return <AllRankedReferences info={info} />;
                case NavigationTab.FACTS_USED_IN_RESPONSE:
                    return <ReferencesUsedInAnswer info={info} />;
            }
        },
    );

    return (
        <>
            <NavigationBar<NavigationTab>
                selected={selectedNavigationTab}
                setSelected={setSelectedNavigationTab}
            >
                {navigationTabs}
            </NavigationBar>
            <TabContent
                selectedNavigationTab={selectedNavigationTab}
                info={info}
                openSearchResult={openSearchResult}
            />
        </>
    );
}

interface OracleAnswerImageCardProps {
    stepNum: number;
    imageSrc: string;
    captionText: string;
    altText: string;
}

function OracleAnswerImageCard({
    stepNum,
    imageSrc,
    captionText,
    altText,
}: OracleAnswerImageCardProps): ReactNode {
    return (
        <div className={"oracle-answer-creation-image-card"}>
            <H3.Tiny>Step {stepNum}</H3.Tiny>
            <img src={imageSrc} className={"margin-bottom-8"} alt={altText} />
            <Paragraph.Small className={"oracle-answer-creation-image-card__description"}>
                {captionText}
            </Paragraph.Small>
        </div>
    );
}

function OracleAnswerCreationIcon(): ReactNode {
    const resourcesIconRef = useRef<SVGSVGElement>(null);
    const resourcesIconTooltipId = useId();
    const [showAnswerInfoNonModal, setShowAnswerInfoNonModal] = useState(false);

    return (
        <>
            <IconButton onClick={() => setShowAnswerInfoNonModal(true)}>
                <Icon.InfoCircle
                    ref={resourcesIconRef}
                    size={IconSize.SMALL}
                    aria-describedby={resourcesIconTooltipId}
                />
            </IconButton>
            <NonModal
                title={"Creating an answer"}
                size={DialogSize.MEDIUM}
                visible={showAnswerInfoNonModal}
                onHide={() => setShowAnswerInfoNonModal(false)}
            >
                {/*TODO - fix alignment/centering of cards*/}
                <div className={"flex-horizontal gap-16"}>
                    <OracleAnswerImageCard
                        stepNum={1}
                        imageSrc={"/images/oracle/answer-creation-1.svg"}
                        captionText={
                            "Based on your question, Everlaw identifies a set of a few hundred "
                            + "documents that could contain relevant information"
                        }
                        altText={
                            "Boxes are displayed in 2 rows, representing documents that are "
                            + "identified as potentially relevant"
                        }
                    />
                    <OracleAnswerImageCard
                        stepNum={2}
                        imageSrc={"/images/oracle/answer-creation-2.svg"}
                        captionText={
                            "Facts related to your question are generated from the top documents "
                            + "and ranked on a 1-5 scale for relevance"
                        }
                        altText={
                            "Lines within the boxes represent the facts that are generated for a "
                            + "subset of potentially relevant documents"
                        }
                    />
                    <OracleAnswerImageCard
                        stepNum={3}
                        imageSrc={"/images/oracle/answer-creation-3.svg"}
                        captionText={
                            "Facts with the highest relevance score are used to generate the "
                            + "response"
                        }
                        altText={
                            "Numbers are added to the boxes, representing the relevance scores "
                            + "given to documents with generated facts"
                        }
                    />
                </div>
            </NonModal>
            <Tooltip id={resourcesIconTooltipId} target={resourcesIconRef}>
                View how answers are created
            </Tooltip>
        </>
    );
}

interface OracleAnswerProps {
    info: OracleInfo;
}

function OracleAnswer({ info }: OracleAnswerProps): ReactNode {
    const citationsLoaded = info.answerCitations !== undefined;
    const [showReferences, setShowReferences] = useState(false);

    const copyIconRef = useRef<SVGSVGElement>(null);
    const copyIconTooltipId = useId();

    useEffect(() => {
        if (!citationsLoaded) {
            getCitationsForAnswer(info.id as number, info.consolidatedAnswer.answerId);
        }
    }, [info, citationsLoaded]);

    useEffect(() => {
        getNumPotentiallyRelevantDocs(info.id as number);
    }, [info]);

    const userHasDeletePerms = User.me.can(Perm.GENERATE_ORACLE, Project.CURRENT);

    return (
        info.consolidatedAnswer && (
            <div className={"oracle-flex-vertical gap-8"}>
                {info.consolidatedAnswer.answerText && (
                    <div>
                        <TextWithCitations info={info} />
                    </div>
                )}
                {citationsLoaded && (
                    <>
                        <div className={"oracle-flex-vertical gap-8"}>
                            <div className={"flex-centered gap-4"}>
                                <button
                                    className={"oracle-ref-table-button"}
                                    onClick={() => setShowReferences(!showReferences)}
                                    aria-label={
                                        showReferences ? "Hide references" : "Close references"
                                    }
                                >
                                    <div>
                                        {showReferences ? (
                                            <Icon.ChevronDown size={20} aria-hidden={true} />
                                        ) : (
                                            <Icon.ChevronRight size={20} aria-hidden={true} />
                                        )}
                                    </div>
                                    <Span.Semibold>Resources</Span.Semibold>
                                </button>
                                <OracleAnswerCreationIcon />
                            </div>
                            {showReferences && <OracleReferences info={info} />}
                        </div>
                    </>
                )}
                <div className={"flex-horizontal"}>
                    <LlmFeedback
                        completionIds={[info.consolidatedAnswer.completionId]}
                        iconSize={IconSize.SMALL}
                        toastBoxLocation={ToastBoxLocation.DEFAULT}
                    />
                    <div>
                        <IconButton
                            onClick={() => {
                                copyPlainTextToClipboard(textWithoutCitations({ info })).then(
                                    () => {
                                        addToast({
                                            icon: "CircleCheck",
                                            title: "Response copied to clipboard",
                                        });
                                    },
                                );
                            }}
                            aria-describedby={copyIconTooltipId}
                        >
                            <Icon.Clipboard ref={copyIconRef} />
                        </IconButton>
                        <Tooltip id={copyIconTooltipId} target={copyIconRef}>
                            Copy to clipboard
                        </Tooltip>
                        {userHasDeletePerms && <OracleDeletionIcon info={info} />}
                    </div>
                </div>
            </div>
        )
    );
}

interface HeaderInfoProps {
    info: OracleInfo;
}

function HeaderInfo({ info }: HeaderInfoProps): ReactNode {
    const { timestamp, searchResultId, name } = info.questionSubmission;

    return (
        <Paragraph.Small className={"oracle-info-header-text"}>
            {displayDateTime(timestamp)} •{" "}
            {searchResultId ? (
                <>
                    Searched across{" "}
                    <Link
                        href={Project.getCurrentProject().urlFor("search", { id: searchResultId })}
                    >
                        <Span.Small>specific document set</Span.Small>
                    </Link>
                </>
            ) : (
                "Searched across entire document set"
            )}{" "}
            • {name}
        </Paragraph.Small>
    );
}

interface OracleGeneratingStateProps {
    info: OracleInfo;
}

function OracleGeneratingState({ info }: OracleGeneratingStateProps): ReactNode {
    let msg;
    if (info.currentStep === OracleQueryStep.QUESTION_SUBMISSION) {
        msg = "Identifying possibly relevant documents";
    } else if (info.currentStep === OracleQueryStep.QUESTION_SEARCH && info.searchResult) {
        msg = `Identified ${info.searchResult.numRelevantDocs} possibly relevant documents and preparing for fact extraction`;
    } else if (
        info.currentStep === OracleQueryStep.EXCERPTS_EXTRACTION
        && info.excerptsExtraction
    ) {
        msg = `Analyzing ${info.excerptsExtraction.numDocsEvaluated} documents for relevance`;
    } else if (info.currentStep === OracleQueryStep.FACTOIDS_RESPONSE && info.factoidsResponse) {
        msg = `Generating response from ${info.factoidsResponse.factIds.length} most relevant facts`;
    }
    return (
        <div className={"flex gap-8"}>
            <CommonIcon.AutoGenerating />
            <div>
                <div className={"oracle-generating-response-text"}>Generating response</div>
                {msg && <div className={"bb-text--color-secondary bb-text--small"}>{msg}</div>}
            </div>
        </div>
    );
}

interface OracleErrorStateProps {
    info: OracleInfo;
}

function OracleErrorState({ info }: OracleErrorStateProps): ReactNode {
    return (
        <div className={"oracle-flex-vertical gap-8"}>
            <div className={"flex gap-8"}>
                <CommonIcon.ErrorTriangle size={IconSize.SMALL} />
                <div>
                    <Paragraph className={"bb-text--color-danger"}>
                        There was an error generating a response to this question
                    </Paragraph>
                </div>
            </div>
            {info.userId === User.me.id && (
                <div className={"oracle-error-footer"}>
                    <OracleDeletionIcon info={info} />
                </div>
            )}
        </div>
    );
}

interface OracleDeletionIconProps {
    info: OracleInfo;
}

function OracleDeletionIcon({ info }: OracleDeletionIconProps): ReactNode {
    const deleteIconRef = useRef<SVGSVGElement>(null);
    const deleteIconTooltipId = useId();

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    return (
        <>
            <IconButton
                onClick={() => setShowDeleteDialog(true)}
                aria-describedby={deleteIconTooltipId}
            >
                <Icon.Trash ref={deleteIconRef} />
            </IconButton>
            <Tooltip id={deleteIconTooltipId} target={deleteIconRef}>
                Delete response
            </Tooltip>
            <Confirmation
                title={"Delete Q&A generation"}
                size={DialogSize.SMALL}
                onHide={() => setShowDeleteDialog(false)}
                onCancel={() => setShowDeleteDialog(false)}
                onComplete={() => {
                    deleteInfo(info);
                    setShowDeleteDialog(false);
                }}
                visible={showDeleteDialog}
                primaryButton={<Button color={ButtonColor.DANGER}>Delete</Button>}
            >
                <Paragraph>Are you sure you want to delete this Q&A generation?</Paragraph>
            </Confirmation>
        </>
    );
}
