import { Str } from "core";
import {
    EverColor,
    Icon,
    IconButton,
    Popover,
    TextButton,
    Tooltip,
    Span,
    Paragraph,
} from "design-system";
import * as Base from "Everlaw/Base";
import { useStore } from "Everlaw/Base";
import {
    getFactoids,
    openDocInNewWindow,
    OracleCitedFactoid,
    OracleFactoidObj,
    OracleInfo,
} from "Everlaw/Oracle/OracleUtils";
import * as React from "react";
import { ReactNode, useCallback, useMemo, useRef, useState } from "react";

interface ViewDocumentButtonProps {
    docId: number;
}

function ViewDocumentButton({ docId }: ViewDocumentButtonProps): ReactNode {
    return <TextButton onClick={() => openDocInNewWindow(docId)}>View Document</TextButton>;
}

interface CitationContentProps {
    citedFactoid: OracleCitedFactoid;
    factObj: OracleFactoidObj;
    needOuterDiv: boolean;
}

function CitationContent({ citedFactoid, factObj, needOuterDiv }: CitationContentProps): ReactNode {
    const innerContent = (
        <>
            <Paragraph>
                <Span.Bold
                    className={"bb-text-small bb-text--color-secondary"}
                >{`${citedFactoid.referenceId} • ${citedFactoid.batesDisplay}`}</Span.Bold>
            </Paragraph>
            <Paragraph>{factObj?.quote}</Paragraph>
            <ViewDocumentButton docId={citedFactoid.docId} />
        </>
    );
    return needOuterDiv ? (
        <div className={"oracle-citation-content"}>{innerContent}</div>
    ) : (
        innerContent
    );
}

interface CitationProps {
    citedFactoid: OracleCitedFactoid;
    submissionId: number;
}

/**
 * Custom hook to manage the action of fetching and displaying factoids.
 *
 * @param {OracleCitedFactoid[]} citedFactoids - An array of cited factoids that need to be checked
 * and potentially fetched.
 * @param {number} submissionId - The submission ID associated with the factoids.
 * @returns {Object} An object containing:
 * - `show`: A boolean state indicating whether the factoids should be displayed.
 * - `setShow`: A function to update the `show` state.
 * - `handleAction`: A callback function to handle the action of fetching factoids if needed and
 * updating the display state.
 * - `factRefs`: An array of factoid references from the global store.
 */
function useFactoidAction(citedFactoids: OracleCitedFactoid[], submissionId: number) {
    const [show, setShow] = useState(false);
    const factRefs = useStore(Base.globalStore(OracleFactoidObj));

    const cachedFactIds = useMemo(
        () => new Set(factRefs.map((ref) => ref.obj.id as number)),
        [factRefs],
    );
    const factIdsToFetch = useMemo(
        () => citedFactoids.map((f) => f.factId).filter((f) => !cachedFactIds.has(f)),
        [citedFactoids, cachedFactIds],
    );

    const handleAction = useCallback(() => {
        if (factIdsToFetch.length > 0) {
            getFactoids(factIdsToFetch, submissionId).then(() => {
                setShow(true);
            });
        } else {
            setShow(true);
        }
    }, [factIdsToFetch, submissionId]);

    return { show, setShow, handleAction, factRefs };
}

/**
 * Citation component for single citations for when there are less than
 * {@link CITATION_NUMBER_CUTOFF} citations The component opens the main Popover containing answer
 * reference information on **hover**
 */
export function Citation({ citedFactoid, submissionId }: CitationProps): ReactNode {
    const ref = useRef(null);
    const { handleAction } = useFactoidAction([citedFactoid], submissionId);

    const factObj = Base.get(OracleFactoidObj, citedFactoid.factId);

    return (
        <>
            <div
                className={"oracle-ref-citation-wrapper"}
                tabIndex={0}
                aria-label={`Open citation number ${citedFactoid.factId}`}
                ref={ref}
            >
                <cite className={"oracle-ref-citation"}>{citedFactoid.referenceId}</cite>
            </div>
            <Tooltip
                onShow={handleAction}
                className={"oracle-citation-tooltip"}
                target={ref}
                renderOutsideParent={false}
            >
                <CitationContent
                    citedFactoid={citedFactoid}
                    factObj={factObj}
                    needOuterDiv={false}
                />
            </Tooltip>
        </>
    );
}

const CITATION_NUMBER_CUTOFF = 5;

interface MultipleCitationsProps {
    citedFactoids: OracleCitedFactoid[];
    submissionId: number;
}

/**
 * Citation component to group citations for when there are more than {@link
 * CITATION_NUMBER_CUTOFF} citations. The component opens the main Popover containing answer
 * reference information on **click** and opens a tooltip indicating the number of citations on
 * **hover**.
 */
export function MultipleCitations({
    citedFactoids,
    submissionId,
}: MultipleCitationsProps): ReactNode {
    const ref = useRef(null);
    const { show, setShow, handleAction } = useFactoidAction(citedFactoids, submissionId);
    const refDisplayMsg = Str.countOf(citedFactoids.length, "more reference");
    const ariaLabelMsg = `Open ${Str.countOf(citedFactoids.length, "more citation")}`;

    return (
        <>
            <div ref={ref}>
                <IconButton onClick={() => handleAction()} aria-label={ariaLabelMsg}>
                    <Icon.Dots aria-hidden={true} size={12} color={EverColor.EVERBLUE_50} />
                </IconButton>
                <Popover show={show} setShow={setShow} target={ref}>
                    {citedFactoids.map((f, index, arr) => {
                        const factObj = Base.get(OracleFactoidObj, f.factId);
                        return (
                            <React.Fragment key={f.factId}>
                                <CitationContent
                                    citedFactoid={f}
                                    factObj={factObj}
                                    needOuterDiv={true}
                                />
                                {index < arr.length - 1 && <hr />}
                            </React.Fragment>
                        );
                    })}
                </Popover>
            </div>
            <Tooltip aria-label={refDisplayMsg} target={ref} renderOutsideParent={true}>
                {refDisplayMsg}
            </Tooltip>
        </>
    );
}

interface TextWithCitationsProps {
    info: OracleInfo;
}

const ANSWER_CITATION_REGEX_PATTERN = /\[(\d+(?:,\s*\d+)*)\]/g;

/**
 * A React component that renders text with inline citations.
 *
 * This component parses a text string containing citation references in the format [n] or [n,m,o],
 * where n, m, and o are numeric citation identifiers. It splits the text at these references and
 * replaces them with interactive citation components while preserving the original text structure.
 *
 * @component
 * @example
 * const info = {
 *   consolidatedAnswer: {
 *     answerText: "This is a sample text [1] with multiple citations [2,3]."
 *   }
 * };
 */
export function TextWithCitations({ info }: TextWithCitationsProps): ReactNode {
    const text = info.consolidatedAnswer.answerText;
    const parts: ReactNode[] = [];
    const regex = new RegExp(ANSWER_CITATION_REGEX_PATTERN);
    let lastIndex = 0;
    let match;

    while ((match = regex.exec(text)) !== null) {
        // Add text before the citation
        if (match.index > lastIndex) {
            parts.push(text.slice(lastIndex, match.index));
        }

        // Add the citation component
        const citations = match[1].split(",").map((num) => parseInt(num.trim(), 10));
        parts.push(<CitationGroup info={info} key={match.index} refIds={citations} />);

        lastIndex = regex.lastIndex;
    }

    // Add any remaining text after the last citation
    if (lastIndex < text?.length) {
        parts.push(text.slice(lastIndex));
    }

    return <>{parts}</>;
}

export function textWithoutCitations({ info }: TextWithCitationsProps): string {
    const text = info.consolidatedAnswer.answerText;
    const parts: string[] = [];
    const regex = new RegExp(ANSWER_CITATION_REGEX_PATTERN);
    let lastIndex = 0;
    let match;

    while ((match = regex.exec(text)) !== null) {
        // Add text before the citation, stripping trailing whitespace
        if (match.index > lastIndex) {
            parts.push(text.slice(lastIndex, match.index).trimEnd());
        }

        // Unlike TextWithCitations, skip citation text
        lastIndex = regex.lastIndex;
    }

    // Add any remaining text after the last citation
    if (lastIndex < text?.length) {
        parts.push(text.slice(lastIndex));
    }

    return parts.join("");
}

interface CitationGroupProps {
    info: OracleInfo;
    refIds: number[];
}

function CitationGroup({ info, refIds }: CitationGroupProps): ReactNode {
    const factIdToFactoidMap = useMemo(
        () => new Map((info?.answerCitations ?? []).map((f) => [f.referenceId, f])),
        [info?.answerCitations],
    );

    return (
        <div className={"oracle-citation-group"}>
            {refIds
                .slice(0, CITATION_NUMBER_CUTOFF)
                .map((id) => factIdToFactoidMap.get(id))
                .filter((f) => f !== undefined)
                .map((f) => (
                    <Citation
                        key={f.factId}
                        citedFactoid={f}
                        submissionId={info.questionSubmission.submissionId}
                    />
                ))}
            {refIds.length > CITATION_NUMBER_CUTOFF && (
                <MultipleCitations
                    citedFactoids={refIds
                        .slice(CITATION_NUMBER_CUTOFF)
                        .map((id) => factIdToFactoidMap.get(id))
                        .filter((f) => f !== undefined)}
                    submissionId={info.questionSubmission.submissionId}
                />
            )}
        </div>
    );
}
