import * as Base from "Everlaw/Base";
import { ObjJson } from "Everlaw/Base";
import * as Multiplex from "Everlaw/Multiplex";
import * as Rest from "Everlaw/Rest";
import { Subscription } from "Everlaw/Multiplex";
import * as BaseResultsPage from "Everlaw/BaseResultsPage";
import * as Project from "Everlaw/Project";

export enum OracleQueryStep {
    QUESTION_SUBMISSION,
    QUESTION_SEARCH,
    EXCERPTS_EXTRACTION,
    FACTOIDS_RESPONSE,
    ANSWER_CONSOLIDATION,
}

export function subscribeToOracleByProject(): Subscription {
    return Multiplex.subscribe({
        method: "POST",
        url: "subscribeToOracleByProject.rest",
        success: (sub: Multiplex.Subscription): void => {
            sub.begin((data) => {
                data.currentStep = OracleQueryStep[data.currentStep];
                Base.set(OracleInfo, data);
            });
            fetchInProgressCompletionsByProject();
        },
    });
}

export function subscribeToOracleByUser(): Subscription {
    return Multiplex.subscribe({
        method: "POST",
        url: "subscribeToOracleByUser.rest",
        success: (sub: Multiplex.Subscription): void => {
            sub.begin((data) => {
                data.currentStep = OracleQueryStep[data.currentStep];
                Base.set(OracleInfo, data);
            });
            fetchInProgressCompletionsByUser();
        },
    });
}

export function getFactoids(factIds: number[], submissionId: number): Promise<void> {
    return Rest.get("getOracleFactoids.rest", { factIds, submissionId }).then((data) => {
        Base.set(OracleFactoidObj, data);
    });
}

export function getNumPotentiallyRelevantDocs(submissionId: number): void {
    Rest.get("getOracleNumPotentiallyRelevantDocs.rest", { submissionId }).then(
        (numRelevantDocs) => {
            Base.set(OracleInfo, {
                id: submissionId,
                searchResult: { numRelevantDocs: numRelevantDocs },
            } as ObjJson);
        },
    );
}

/**
 * Fetches the completed questions by project.
 * @param excludeIds Specify the ids of questions that you do not want load onto the frontend if
 * they are already loaded.
 */
export function getCompletedQuestionsByProject(excludeIds: number[]): Promise<void> {
    return Rest.post("getCompletedQuestionsByProject.rest", { excludeIds }).then((data) => {
        data.forEach(
            // converting string sent by backend to OracleQueryStep enum
            (info: { currentStep: keyof typeof OracleQueryStep | OracleQueryStep }) =>
                (info.currentStep =
                    OracleQueryStep[info.currentStep as keyof typeof OracleQueryStep]),
        );
        data.currentStep = OracleQueryStep[data.currentStep];
        Base.set(OracleInfo, data);
    });
}

/**
 * Fetches the completed questions by user.
 * @param excludeIds Specify the ids of questions that you do not want load onto the frontend if
 * they are already loaded.
 */
export function getCompletedQuestionsByUser(excludeIds: number[]): Promise<void> {
    return Rest.post("getCompletedQuestionsByUser.rest", { excludeIds }).then((data) => {
        data.forEach(
            // converting string sent by backend to OracleQueryStep enum
            (info: { currentStep: keyof typeof OracleQueryStep | OracleQueryStep }) =>
                (info.currentStep =
                    OracleQueryStep[info.currentStep as keyof typeof OracleQueryStep]),
        );
        data.currentStep = OracleQueryStep[data.currentStep];
        Base.set(OracleInfo, data);
    });
}

export function getAllRankedFactoidsForAnswer(
    submissionId: number,
    answerId: number,
): Promise<OracleInfo> {
    return Rest.get("getAllRankedFactoidsForAnswer.rest", { submissionId, answerId }).then(
        (data) => {
            Base.set(OracleFactoidObj, data.factoids);
            return Base.set(OracleInfo, {
                id: submissionId,
                allFactoids: data.docInfo,
            } as ObjJson);
        },
    );
}

export function getCitationsForAnswer(submissionId: number, answerId: number): void {
    Rest.get("getCitationsForAnswer.rest", { submissionId, answerId }).then((data) => {
        Base.set(OracleInfo, {
            id: submissionId,
            answerCitations: data,
        } as ObjJson);
    });
}

export function fetchInProgressCompletionsByProject(): void {
    Rest.post("fetchInProgressCompletionsByProject.rest", {}).catch(() => {
        throw new Error("Error fetching in progress answers by project");
    });
}

export function fetchInProgressCompletionsByUser(): void {
    Rest.post("fetchInProgressCompletionsByUser.rest", {}).catch(() => {
        throw new Error("Error fetching in progress answers by user");
    });
}

export function getSearchResultURL(submissionId: number): Promise<void> {
    return Rest.get("getOracleSearchResultURL.rest", { submissionId }).then((url) => {
        Base.set(OracleInfo, { id: submissionId, searchResultUrl: url } as ObjJson);
    });
}

export function deleteInfo(info: OracleInfo): Promise<void> {
    return Rest.post("deleteBySubmissionId.rest", { submissionId: info.id })
        .then(() => {
            Base.remove(info);
        })
        .catch((e) => {
            throw new Error(e);
        });
}

export function getCaseContext(): Promise<string> {
    return Rest.get("getCaseContext.rest");
}

export function setCaseContext(caseContext: string): Promise<void> {
    return Rest.post("setCaseContext.rest", { caseContext });
}

export function openDocInNewWindow(docId: number | undefined): void {
    if (!docId) {
        return;
    }
    BaseResultsPage.openInNewDoc(
        Project.getCurrentProject().urlFor("review", { doc: docId }),
        "oracle",
    );
}

export class OracleFactoidObj extends Base.Object {
    get className(): string {
        return "OracleFactoidText";
    }
    quote: string;
    relevanceScore: number;
    referenceId: number | undefined;

    constructor(params: OracleInfo) {
        super(params);
        this._mixin(params);
    }

    override _mixin(params: OracleInfo): void {
        Object.assign(this, params);
    }
}

export class OracleInfo extends Base.Object {
    get className(): string {
        return "OracleInfo";
    }
    userId: number;
    currentStep: OracleQueryStep;
    questionSubmission: QuestionSubmissionInfo;
    searchResult: SearchResultInfo;
    excerptsExtraction: ExcerptsExtractionInfo;
    factoidsResponse: FactoidsResponseInfo;
    consolidatedAnswer: ConsolidatedAnswerInfo;
    // The following fields are separate because they are lazy loaded
    answerCitations: OracleCitedFactoid[];
    allFactoids: OracleFactoid[];
    searchResultUrl: string;

    constructor(params: OracleInfo) {
        super(params);
        this._mixin(params);
    }

    override _mixin(params: OracleInfo): void {
        if (params.currentStep !== undefined) {
            if (
                ((params.currentStep === OracleQueryStep.QUESTION_SUBMISSION
                    || params.currentStep === OracleQueryStep.ANSWER_CONSOLIDATION)
                    && this.currentStep === undefined)
                || (params.currentStep !== OracleQueryStep.QUESTION_SUBMISSION
                    && params.currentStep === this.currentStep + 1)
                || (params.questionSubmission.hasError && !this.questionSubmission.hasError)
            ) {
                Object.assign(this, params);
            } else {
                console.warn("Data from backend was sent out of order");
            }
        } else {
            Object.assign(this, params);
        }
    }
}

// for all relevant factoids
export interface OracleFactoid {
    factId: number;
    docId: number;
    batesDisplay: string;
}

// for factoids used in citation
export interface OracleCitedFactoid {
    referenceId: number;
    factId: number;
    docId: number;
    batesDisplay: string;
}

interface SearchResultInfo {
    numRelevantDocs: number; // This is lazy loaded for already completed answers
}

interface ExcerptsExtractionInfo {
    numDocsEvaluated: number;
}

interface FactoidsResponseInfo {
    factIds: number[];
}

interface QuestionSubmissionInfo {
    questionText: string;
    timestamp: number;
    submissionId: number;
    searchResultId: number;
    name: string;
    hasError: boolean;
}

interface ConsolidatedAnswerInfo {
    answerId: number;
    answerText: string;
    completionId: number;
}
