import {
    DialogSize,
    H3,
    HeadingMargin,
    Icon,
    IconButton,
    NonModal,
    PopoverMenu,
    PopoverMenuPlacement,
    Tooltip,
} from "design-system";
import { OracleSubmitForm } from "Everlaw/Oracle/OracleSubmitForm";
import { OracleUserTab, OracleProjectTab } from "Everlaw/Oracle/OracleTabs";
import { ReactWidget, wrapReactComponent } from "Everlaw/UI/ReactWidget";
import * as React from "react";
import { ReactNode, useEffect, useId, useRef, useState } from "react";
import * as Project from "Everlaw/Project";
import * as Perm from "Everlaw/PermissionStrings";
import * as User from "Everlaw/User";

enum PanelType {
    User,
    Project,
}

interface PanelHeaderProps {
    panelType: PanelType;
    setPanelType: React.Dispatch<React.SetStateAction<PanelType>>;
}

function PanelHeader({ panelType, setPanelType }: PanelHeaderProps): ReactNode {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const infoIconRef = useRef<SVGSVGElement>(null);
    const userViewTooltipId = useId();
    const [showPopover, setShowPopover] = useState(false);
    const canAccessUserView = User.me.can(Perm.GENERATE_ORACLE, Project.getCurrentProject());

    return (
        <div className={"flex-centered gap-8"}>
            <H3.Large className={"margin-0"} marginType={HeadingMargin.NONE}>
                {panelType === PanelType.User ? "My questions" : "All questions"}
            </H3.Large>
            {panelType === PanelType.User && (
                <>
                    <Icon.InfoCircle
                        ref={infoIconRef}
                        tabIndex={0}
                        aria-describedby={userViewTooltipId}
                    />
                    <Tooltip id={userViewTooltipId} target={infoIconRef}>
                        Users with View and Generate permissions on the Q&A tool will be able to see
                        all your questions
                    </Tooltip>
                </>
            )}
            {canAccessUserView && (
                <>
                    <IconButton
                        ref={buttonRef}
                        onClick={() => setShowPopover((prevState) => !prevState)}
                        aria-label={"Change question view"}
                    >
                        <Icon.ChevronDown />
                    </IconButton>
                    <PopoverMenu
                        trigger={buttonRef}
                        show={showPopover}
                        setShow={setShowPopover}
                        placement={PopoverMenuPlacement.BOTTOM_START}
                    >
                        <PopoverMenu.Option
                            selectable={true}
                            selected={panelType === PanelType.User}
                            label={"My questions"}
                            onClick={() => setPanelType(PanelType.User)}
                        />
                        <PopoverMenu.Option
                            selectable={true}
                            selected={panelType === PanelType.Project}
                            label={"All questions asked in project"}
                            onClick={() => setPanelType(PanelType.Project)}
                        />
                    </PopoverMenu>
                </>
            )}
        </div>
    );
}

interface OraclePanelProps {
    showState: { show: boolean };
}

export function OraclePanel({ showState }: OraclePanelProps): ReactNode {
    const [panelType, setPanelType] = useState<PanelType>(PanelType.Project);
    const { setVisible, nonModalProps } = NonModal.use();
    useEffect(() => {
        setVisible(showState.show);
    }, [showState.show, setVisible]);

    const allProps = {
        ...nonModalProps,
        onHide: () => {
            showState.show = false;
            setVisible(showState.show);
        },
    };

    const userHasGeneratePerms = User.me.can(Perm.GENERATE_ORACLE, Project.getCurrentProject());

    return (
        <NonModal
            className={"oracle-main-dialog"}
            size={DialogSize.LARGE}
            title={"Q&A"}
            minWidth={"100%"}
            minHeight={"95%"}
            initialPosition={{ x: 0, y: 20 }}
            {...allProps}
        >
            <main className={"oracle-main"}>
                {userHasGeneratePerms && <OracleSubmitForm />}
                {panelType === PanelType.User ? (
                    <OracleUserTab
                        panelHeader={
                            <PanelHeader panelType={panelType} setPanelType={setPanelType} />
                        }
                    />
                ) : (
                    <OracleProjectTab
                        panelHeader={
                            <PanelHeader panelType={panelType} setPanelType={setPanelType} />
                        }
                    />
                )}
            </main>
        </NonModal>
    );
}

function createOracle(showState: { show: boolean }): ReactWidget<{ showState: { show: boolean } }> {
    return wrapReactComponent(OraclePanel, {
        showState: showState,
    });
}

/**
 * TODO: put these variables in a better place
 * I didn't know the right place to put this "global" state, so I put it in here just to get
 * something going. This should optimally be moved somewhere else.
 */
let oracleContainer: ReactWidget<OraclePanelProps> | undefined = undefined;
const oracleShowState = { show: false };

/**
 * Retrieves the window's Oracle panel, initializing it if necessary.
 * This was made to accomodate the multiple entry points of Oracle: one through the header, and one
 * through the Results table toolbar.
 */
export function initOracle(): ReactWidget<{ showState: { show: boolean } }> {
    if (!oracleContainer) {
        oracleContainer = createOracle(oracleShowState);
    }
    return oracleContainer;
}

export function toggleOracleVisible() {
    oracleShowState.show = !oracleShowState.show;
    oracleContainer?.updateProps({ showState: oracleShowState });
}
