import { containsIgnoreCase, Str } from "core";
import {
    AdvancedTable,
    Icon,
    IconButton,
    PaginationBar,
    Paragraph,
    RowObject,
    Table,
    TableSortingRule,
    TextFilter,
    useBrandedMemo,
} from "design-system";
import * as Base from "Everlaw/Base";
import { useStore } from "Everlaw/Base";
import {
    getAllRankedFactoidsForAnswer,
    getFactoids,
    openDocInNewWindow,
    OracleFactoidObj,
    OracleInfo,
} from "Everlaw/Oracle/OracleUtils";
import * as React from "react";
import { ReactNode, useEffect, useMemo, useState } from "react";

const ratingToString: Record<number, string> = {
    1: "Not relevant",
    2: "Barely relevant",
    3: "Somewhat relevant",
    4: "Directly relevant",
    5: "Highly relevant",
};

interface CitationRelevanceCellContentProps {
    relevanceScore: number;
}

function CitationRelevanceCellContent({
    relevanceScore,
}: CitationRelevanceCellContentProps): ReactNode {
    return (
        <div>
            <Paragraph>{relevanceScore}</Paragraph>
            <Paragraph className={"bb-text--small bb-text--color-secondary"}>
                {ratingToString[relevanceScore]}
            </Paragraph>
        </div>
    );
}

interface GeneratedFactCellProps {
    quote: string;
    batesNumber: string;
    docId: number;
}

function GeneratedFactCell({ quote, batesNumber, docId }: GeneratedFactCellProps): ReactNode {
    return (
        <div className={"flex-horizontal gap-8"}>
            <div className={"oracle-flex-vertical gap-4"}>
                <Paragraph>{quote}</Paragraph>
                <Paragraph className={"bb-text--small bb-text--color-secondary"}>
                    {batesNumber}
                </Paragraph>
            </div>
            <div>
                <IconButton
                    aria-label={"Open document in new window"}
                    onClick={() => openDocInNewWindow(docId)}
                >
                    <Icon.Eye />
                </IconButton>
            </div>
        </div>
    );
}

const PAGE_SIZE = 10;
const TABLE_FILTER_PLACEHOLDER = "Search in table"; // TODO: Double check with product

interface AllRankedReferencesRow extends RowObject {
    id: number;
    referenceId: number | undefined;
    relevanceScore: React.ReactNode;
    generatedFact: React.ReactNode;
}

interface AllRankedReferencesProps {
    info: OracleInfo;
}

export function AllRankedReferences({ info }: AllRankedReferencesProps): ReactNode {
    const factRefs = useStore(Base.globalStore(OracleFactoidObj));
    const factIdToObj = useMemo(
        () => new Map(factRefs.map((ref) => [ref.obj.id, ref.obj])),
        [factRefs],
    );

    // There is room for optimizing data fetching
    useEffect(() => {
        if (info.allFactoids === undefined) {
            getAllRankedFactoidsForAnswer(info.id as number, info.consolidatedAnswer.answerId);
        }
    }, [info]);

    const [sort, setSort] = useState<TableSortingRule<AllRankedReferencesRow> | undefined>({
        id: "relevanceScore",
        desc: true,
    });

    const { inputValue, setInputValue, filterValue, setFilterValue, showInput, setShowInput } =
        TextFilter.use();

    const TABLE_HEADERS = useBrandedMemo(
        () => [
            {
                id: "referenceId",
                title: "Reference",
                width: 128,
                isSortable: true,
                ellipseCellContent: true,
            },
            {
                id: "relevanceScore",
                title: "Relevance",
                width: 152,
                isSortable: true,
                infoIcon: (
                    // TODO: update when copy is done
                    <Table.HeaderInfoIcon tooltipContent={"Tooltip description for user column"} />
                ),
                ellipseCellContent: true,
            },
            {
                id: "generatedFact",
                title: "Generated Fact",
            },
        ],
        [],
    );

    const allTableRows: AllRankedReferencesRow[] = useMemo(
        () =>
            (info.allFactoids ?? [])
                .map((f) => {
                    const factObj = factIdToObj.get(f.factId);
                    return factObj
                        ? {
                              id: f.factId,
                              referenceId: factObj.referenceId,
                              rawRelevanceScore: factObj?.relevanceScore,
                              quote: factObj?.quote,
                              rawBatesDisplay: f.batesDisplay,
                              docId: f.docId,
                          }
                        : undefined;
                })
                .filter((row) => row !== undefined)
                .filter((row) => (filterValue ? containsIgnoreCase(row.quote, filterValue) : true))
                .sort((a, b) => {
                    const sortId = sort === undefined ? "relevanceScore" : sort.id;
                    let compare: number;
                    switch (sortId) {
                        case "relevanceScore":
                            compare = a.rawRelevanceScore - b.rawRelevanceScore;
                            break;
                        default:
                            throw new Error("Invalid column name");
                    }
                    if (sort === undefined || sort.desc) {
                        compare = -compare;
                    }
                    return compare;
                })
                .map((row) => ({
                    id: row.id,
                    referenceId: row.referenceId,
                    relevanceScore: (
                        <CitationRelevanceCellContent relevanceScore={row.rawRelevanceScore} />
                    ),
                    generatedFact: (
                        <GeneratedFactCell
                            quote={row.quote}
                            batesNumber={row.rawBatesDisplay}
                            docId={row.docId}
                        />
                    ),
                })),
        [info.allFactoids, filterValue, sort, factIdToObj],
    );

    const [currentPage, setCurrentPage] = useState(1);

    const rows = useBrandedMemo<AllRankedReferencesRow[]>(() => {
        const start = (currentPage - 1) * PAGE_SIZE;
        return allTableRows.slice(start, start + PAGE_SIZE);
    }, [currentPage, allTableRows]);

    return (
        <>
            <div className={"oracle-table-action-bar"}>
                <div className={"flex-centered"}>
                    {Str.countOf(info.allFactoids?.length ?? 0, "reference")}
                </div>
                <Table.ActionBar
                    textFilter={
                        <TextFilter
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                            setFilterValue={setFilterValue}
                            showInput={showInput}
                            setShowInput={setShowInput}
                            aria-label={TABLE_FILTER_PLACEHOLDER}
                            placeholder={TABLE_FILTER_PLACEHOLDER}
                        />
                    }
                />
            </div>
            <AdvancedTable
                aria-label={"All references ranked by LLM for the answer"}
                headers={TABLE_HEADERS}
                objects={rows}
                sortManually={true}
                currentSort={sort}
                setCurrentSort={(newSort?: TableSortingRule<AllRankedReferencesRow>) => {
                    setSort(newSort);
                }}
                placeholder={"No references to display"}
                getKey={(obj) => obj.id}
                showGridLines={true}
                minWidth={500}
                maxWidth={800}
                paginationBar={
                    <PaginationBar
                        numTotalRows={allTableRows.length}
                        pageSize={PAGE_SIZE}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                }
            />
        </>
    );
}

interface ReferencesUsedInAnswerRow extends RowObject {
    id: number;
    referenceId: number;
    relevanceScore: ReactNode;
    generatedFact: ReactNode;
}

interface ReferencesUsedInAnswerProps {
    info: OracleInfo;
}

export function ReferencesUsedInAnswer({ info }: ReferencesUsedInAnswerProps): ReactNode {
    const factRefs = useStore(Base.globalStore(OracleFactoidObj));
    const factIdToObj = useMemo(
        () => new Map(factRefs.map((ref) => [ref.obj.id, ref.obj])),
        [factRefs],
    );

    const fIds = useMemo(
        () => new Set(info.answerCitations.map((f) => f.factId)),
        [info.answerCitations],
    );

    useEffect(() => {
        if (!fIds) {
            return;
        }
        const fIdsToFetch = [...fIds].filter((id) => Base.get(OracleFactoidObj, id) === undefined);
        if (fIdsToFetch.length > 0) {
            getFactoids(fIdsToFetch, info.questionSubmission.submissionId);
        }
    }, [fIds, info.questionSubmission.submissionId]);

    const { inputValue, setInputValue, filterValue, setFilterValue, showInput, setShowInput } =
        TextFilter.use();

    const [sort, setSort] = useState<TableSortingRule<ReferencesUsedInAnswerRow> | undefined>({
        id: "referenceId",
        desc: false,
    });

    const TABLE_HEADERS = useBrandedMemo(
        () => [
            {
                id: "referenceId",
                title: "Reference",
                width: 128,
                isSortable: true,
                ellipseCellContent: true,
            },
            {
                id: "relevanceScore",
                title: "Relevance",
                width: 152,
                isSortable: true,
                infoIcon: (
                    // TODO: update when copy is done
                    <Table.HeaderInfoIcon tooltipContent={"Tooltip description for user column"} />
                ),
                ellipseCellContent: true,
            },
            {
                id: "generatedFact",
                title: "Generated Fact",
            },
        ],
        [],
    );

    const allTableRows: ReferencesUsedInAnswerRow[] = useMemo(
        () =>
            (info.answerCitations ?? [])
                .map((f) => {
                    const factObj = factIdToObj.get(f.factId);
                    return factObj
                        ? {
                              id: f.factId,
                              referenceId: f.referenceId,
                              rawRelevanceScore: factObj?.relevanceScore,
                              quote: factObj.quote,
                              rawBatesDisplay: f.batesDisplay,
                              docId: f.docId,
                          }
                        : undefined;
                })
                .filter((row) => row !== undefined)
                .filter((row) => (filterValue ? containsIgnoreCase(row.quote, filterValue) : true))
                .sort((a, b) => {
                    const sortId = sort === undefined ? "referenceId" : sort.id;
                    let compare: number;
                    switch (sortId) {
                        case "referenceId":
                            compare = a.referenceId - b.referenceId;
                            break;
                        default:
                            throw new Error("Invalid column name");
                    }
                    if (sort?.desc) {
                        compare = -compare;
                    }
                    return compare;
                })
                .map((row) => ({
                    id: row.id,
                    referenceId: row.referenceId,
                    relevanceScore: (
                        <CitationRelevanceCellContent relevanceScore={row.rawRelevanceScore} />
                    ),
                    generatedFact: (
                        <GeneratedFactCell
                            quote={row.quote}
                            batesNumber={row.rawBatesDisplay}
                            docId={row.docId}
                        />
                    ),
                })),
        [info, factIdToObj, filterValue, sort],
    );

    const [currentPage, setCurrentPage] = useState(1);

    const rows = useBrandedMemo<ReferencesUsedInAnswerRow[]>(() => {
        const start = (currentPage - 1) * PAGE_SIZE;
        return allTableRows.slice(start, start + PAGE_SIZE);
    }, [currentPage, allTableRows]);

    return (
        <>
            <div className={"oracle-table-action-bar"}>
                <div className={"flex-centered"}>
                    {Str.countOf(info.answerCitations.length, "reference")}
                </div>
                <Table.ActionBar
                    textFilter={
                        <TextFilter
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                            setFilterValue={setFilterValue}
                            showInput={showInput}
                            setShowInput={setShowInput}
                            aria-label={TABLE_FILTER_PLACEHOLDER}
                            placeholder={TABLE_FILTER_PLACEHOLDER}
                        />
                    }
                />
            </div>
            <AdvancedTable
                aria-label={"Highly relevant references used by LLM for the answer"}
                headers={TABLE_HEADERS}
                sortManually={true}
                currentSort={sort}
                setCurrentSort={(newSort?: TableSortingRule<ReferencesUsedInAnswerRow>) => {
                    setSort(newSort);
                }}
                objects={rows}
                placeholder={"No references to display"}
                getKey={(obj) => obj.id}
                showGridLines={true}
                minWidth={500}
                maxWidth={800}
                paginationBar={
                    <PaginationBar
                        numTotalRows={allTableRows.length}
                        pageSize={PAGE_SIZE}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                }
            />
        </>
    );
}
