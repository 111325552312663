import {
    DropdownMenu,
    H3,
    Icon,
    InlineBanner,
    InlineDropdownMenu,
    PaginationBar,
    Paragraph,
    TextButton,
    TextFilter,
} from "design-system";
import * as User from "Everlaw/User";
import * as Base from "Everlaw/Base";
import { useStore } from "Everlaw/Base";
import { containsIgnoreCase } from "core";
import { Subscription } from "Everlaw/Multiplex";
import { OracleCard } from "Everlaw/Oracle/OracleComponents";
import {
    getCompletedQuestionsByProject,
    getCompletedQuestionsByUser,
    OracleInfo,
    OracleQueryStep,
    subscribeToOracleByProject,
    subscribeToOracleByUser,
} from "Everlaw/Oracle/OracleUtils";
import { usePromise } from "Everlaw/Rest";
import * as React from "react";
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";

const PAGE_SIZE = 10;

interface ExpandOrCollapseAllButtonsProps {
    onExpandAll: () => void;
    onCollapseAll: () => void;
    disableExpand: boolean;
    disableCollapse: boolean;
}

function ExpandOrCollapseAllButtons({
    onExpandAll,
    onCollapseAll,
    disableExpand,
    disableCollapse,
}: ExpandOrCollapseAllButtonsProps): ReactNode {
    return (
        <div>
            <TextButton disabled={disableExpand} onClick={onExpandAll}>
                Expand all
            </TextButton>
            <span aria-hidden={true}>/</span>
            <TextButton disabled={disableCollapse} onClick={onCollapseAll}>
                Collapse all
            </TextButton>
        </div>
    );
}

/**
 * Custom hook to manage Oracle information fetching and subscription.
 *
 * This hook handles the fetching of Oracle data based on cached IDs and manages
 * the subscription to updates. It uses a combination of external state from a
 * global store and local state to track the fetching status.
 *
 * @param fetchData - Function to fetch Oracle data for given IDs.
 * @param subscriptionFn - Function to subscribe to Oracle data updates and returns the
 *     subscription object.
 *
 * @returns An object containing Oracle info references, fetch status, and cached IDs.
 */
const useOracleInfo = (
    fetchData: (ids: number[]) => Promise<void>,
    subscriptionFn: () => Subscription,
) => {
    const oracleInfoRefs = useStore(Base.globalStore(OracleInfo));
    const [fetched, setFetched] = useState(false);
    const cachedIds = useMemo(
        () => oracleInfoRefs.map((ref) => ref.obj.id as number),
        [oracleInfoRefs],
    );

    useEffect(() => {
        const sub = subscriptionFn();
        return () => sub.close();
    }, [subscriptionFn]);

    usePromise({
        action: () => {
            if (fetched) {
                return Promise.resolve();
            } else {
                return fetchData(cachedIds);
            }
        },
        onResolve: () => setFetched(true),
        dependencies: [cachedIds, fetched, fetchData],
    });

    return { oracleInfoRefs, fetched, cachedIds };
};

function OracleIntroInfo(): ReactNode {
    return (
        <>
            {/*TODO: replace image when new image is done*/}
            {/* alt text left empty since this isn't critical to experience or functionality */}
            <img src={"/images/everlawAI.svg"} alt={""} />
            <H3 className={"centered"}>Ask questions about your case</H3>
            <Paragraph>
                The Q&A tool will provide you responses with direct references to documents in your
                project, and can help build your case and generate relevant facts from documents.
            </Paragraph>
            <Paragraph.Small>
                Sample questions:
                <ul className={"margin-0"}>
                    <li>
                        How is Good Coffee LLC promoting sustainability in their coffee harvesting?
                    </li>
                    <li>
                        What is Tech Solutions Inc. doing to innovate in their technology
                        development?
                    </li>
                    <li>
                        Do the documents provide evidence of fraudulent activities or financial
                        misconduct?
                    </li>
                </ul>
            </Paragraph.Small>
            <InlineBanner icon={<Icon.InfoCircle />}>
                This is an experimental AI feature. Results may be inaccurate, incomplete, or
                misleading. Please double-check for accuracy.
            </InlineBanner>
        </>
    );
}

interface OracleTabProps {
    panelHeader: ReactNode;
}

export function OracleUserTab({ panelHeader }: OracleTabProps): ReactNode {
    const { oracleInfoRefs } = useOracleInfo(getCompletedQuestionsByUser, subscribeToOracleByUser);
    const userId = User.me.id;
    const [currentPage, setCurrentPage] = useState(1);
    const hasRunEffect = useRef(false);

    const sortedInfo = useMemo(
        () =>
            oracleInfoRefs
                .map((ref) => ref.obj)
                .filter((info) => info.userId === userId)
                .sort((a, b) => b.questionSubmission.timestamp - a.questionSubmission.timestamp),
        [oracleInfoRefs, userId],
    );

    const numAnswers = sortedInfo.length;
    const pageIndex = currentPage - 1;
    const startIndex = pageIndex * PAGE_SIZE;
    const endIndex = Math.min(startIndex + PAGE_SIZE, numAnswers);

    const displayedInfo = useMemo(
        () => sortedInfo.slice(startIndex, endIndex),
        [sortedInfo, startIndex, endIndex],
    );

    const [openStates, setOpenStates] = useState<Record<number, boolean>>({});

    const toggleAll = useCallback(
        (open: boolean) => {
            const newStates: Record<number, boolean> = {};
            displayedInfo.forEach((info) => (newStates[info.id as number] = open));
            setOpenStates(newStates);
        },
        [displayedInfo],
    );

    const toggleOpen = (id: number, open: boolean) => {
        setOpenStates((prev) => ({
            ...prev,
            [id]: open,
        }));
    };

    useEffect(() => {
        if (displayedInfo.length === 0 || hasRunEffect.current) {
            return;
        }
        const newStates: Record<number, boolean> = {};

        // default open if there are fewer than 3 questions
        displayedInfo.forEach(
            (info) =>
                (newStates[info.id as number] =
                    info.currentStep === OracleQueryStep.ANSWER_CONSOLIDATION
                        ? displayedInfo.length <= 2
                        : true),
        );
        setOpenStates(newStates);
        hasRunEffect.current = true;
    }, [displayedInfo]);

    const allOpen = useMemo(
        () => displayedInfo.every((info) => openStates[info.id as number]),
        [displayedInfo, openStates],
    );

    const allClosed = useMemo(
        () => displayedInfo.every((info) => !openStates[info.id as number]),
        [displayedInfo, openStates],
    );

    return (
        <div className={"oracle-flex-vertical gap-16"}>
            <header className={"flex-horizontal"}>
                {panelHeader}
                <ExpandOrCollapseAllButtons
                    onExpandAll={() => toggleAll(true)}
                    onCollapseAll={() => toggleAll(false)}
                    disableExpand={allOpen}
                    disableCollapse={allClosed}
                />
            </header>
            {/*TODO: move OracleIntroInfo to the right place with design feedback*/}
            {numAnswers === 0 && <OracleIntroInfo />}
            {sortedInfo.length > PAGE_SIZE && (
                <PaginationBar
                    currentPage={currentPage}
                    numTotalRows={numAnswers}
                    pageSize={PAGE_SIZE}
                    setCurrentPage={setCurrentPage}
                    disabled={false}
                />
            )}
            <div className={"oracle-flex-vertical gap-16"}>
                {displayedInfo.map((info) => (
                    <OracleCard
                        key={info.id}
                        info={info}
                        toggleOpen={(open: boolean) => toggleOpen(info.id as number, open)}
                        open={openStates[info.id as number]}
                    />
                ))}
            </div>
        </div>
    );
}

export function OracleProjectTab({ panelHeader }: OracleTabProps): ReactNode {
    const { oracleInfoRefs } = useOracleInfo(
        getCompletedQuestionsByProject,
        subscribeToOracleByProject,
    );
    const [currentPage, setCurrentPage] = useState(1);
    const [showSortMenu, setShowSortMenu] = useState(false);
    const [sortAscending, setSortAscending] = useState(false);
    const hasRunEffect = useRef(false);

    const [filteredNames, setFilteredNames] = useState<ReadonlySet<string>>(new Set());
    const { dropdownProps, filterText, onItemClick, isItemSelected } = DropdownMenu.useMulti({
        filterable: true,
        values: filteredNames,
        setValues: setFilteredNames,
        placeholder: "Enter name",
    });

    const userNames = useMemo(
        () => new Set(oracleInfoRefs.map((ref) => ref.obj.questionSubmission.name)),
        [oracleInfoRefs],
    );

    useEffect(() => {
        setFilteredNames(userNames);
    }, [userNames]);

    const { inputValue, setInputValue, filterValue, setFilterValue, showInput, setShowInput } =
        TextFilter.use();
    const isAllChecked = userNames.size === filteredNames.size;

    const sortedInfo = useMemo(
        () =>
            oracleInfoRefs
                .map((ref) => ref.obj)
                .filter(
                    (info) =>
                        filteredNames.size === 0 || filteredNames.has(info.questionSubmission.name),
                )
                .filter(
                    (info) =>
                        containsIgnoreCase(info.questionSubmission.questionText, filterValue ?? "")
                        || containsIgnoreCase(
                            info.consolidatedAnswer.answerText,
                            filterValue ?? "",
                        ),
                )
                .sort((a, b) =>
                    sortAscending
                        ? a.questionSubmission.timestamp - b.questionSubmission.timestamp
                        : b.questionSubmission.timestamp - a.questionSubmission.timestamp,
                ),
        [oracleInfoRefs, sortAscending, filteredNames, filterValue],
    );

    const numAnswers = sortedInfo.length;
    const pageIndex = currentPage - 1;
    const startIndex = pageIndex * PAGE_SIZE;
    const endIndex = Math.min(startIndex + PAGE_SIZE, numAnswers);

    const displayedInfo = useMemo(
        () => sortedInfo.slice(startIndex, endIndex),
        [sortedInfo, startIndex, endIndex],
    );

    const [openStates, setOpenStates] = useState<Record<number, boolean>>({});

    const toggleAll = useCallback(
        (open: boolean) => {
            const newStates: Record<number, boolean> = {};
            displayedInfo.forEach((info) => (newStates[info.id as number] = open));
            setOpenStates(newStates);
        },
        [displayedInfo],
    );

    const toggleOpen = (id: number, open: boolean) => {
        setOpenStates((prev) => ({
            ...prev,
            [id]: open,
        }));
    };

    useEffect(() => {
        if (displayedInfo.length === 0 || hasRunEffect.current) {
            return;
        }
        const newStates: Record<number, boolean> = {};

        // Default open if there are fewer than 3 answers.
        // Answers that are being generated are open on render
        displayedInfo.forEach(
            (info) =>
                (newStates[info.id as number] =
                    info.currentStep === OracleQueryStep.ANSWER_CONSOLIDATION
                        ? displayedInfo.length <= 2
                        : true),
        );
        setOpenStates(newStates);
        hasRunEffect.current = true;
    }, [displayedInfo]);

    const allOpen = useMemo(
        () => displayedInfo.every((info) => openStates[info.id as number]),
        [displayedInfo, openStates],
    );

    const allClosed = useMemo(
        () => displayedInfo.every((info) => !openStates[info.id as number]),
        [displayedInfo, openStates],
    );

    const sortByDateAscLabel = "Sort by date (ascending)";
    const sortByDateDescLabel = "Sort by date (descending)";
    const searchFilterPlaceholder = "Search across project"; // TODO: Double check with Product

    return (
        <div className={"oracle-flex-vertical gap-16"}>
            <header className={"flex-horizontal"}>
                {panelHeader}
                <ExpandOrCollapseAllButtons
                    onExpandAll={() => toggleAll(true)}
                    onCollapseAll={() => toggleAll(false)}
                    disableExpand={allOpen}
                    disableCollapse={allClosed}
                />
            </header>
            <div className={"flex-horizontal"}>
                <div className={"flex gap-8"}>
                    <InlineDropdownMenu
                        value={sortAscending ? sortByDateAscLabel : sortByDateDescLabel}
                        show={showSortMenu}
                        setShow={setShowSortMenu}
                        label={"Sort by date"}
                        width={"180px"}
                    >
                        <DropdownMenu.Section>
                            <DropdownMenu.Option
                                selected={sortAscending}
                                label={sortByDateAscLabel}
                                onClick={() => {
                                    setShowSortMenu(false);
                                    setSortAscending(true);
                                }}
                            />

                            <DropdownMenu.Option
                                selected={!sortAscending}
                                label={sortByDateDescLabel}
                                onClick={() => {
                                    setShowSortMenu(false);
                                    setSortAscending(false);
                                }}
                            />
                        </DropdownMenu.Section>
                    </InlineDropdownMenu>
                    <DropdownMenu
                        label={"Filter by user"}
                        horizontal={true}
                        {...dropdownProps}
                        width={"150px"}
                    >
                        <DropdownMenu.Section>
                            <DropdownMenu.Checkbox
                                value={isAllChecked}
                                label={"(All)"}
                                onChange={() =>
                                    setFilteredNames(() => {
                                        if (isAllChecked) {
                                            return new Set();
                                        } else {
                                            return userNames;
                                        }
                                    })
                                }
                            />
                        </DropdownMenu.Section>
                        <DropdownMenu.Section>
                            {[...userNames]
                                .filter((name) => containsIgnoreCase(name, filterText ?? ""))
                                .map((name) => (
                                    <DropdownMenu.Checkbox
                                        key={name}
                                        label={name}
                                        ellipsify={true}
                                        onChange={() => onItemClick(name)}
                                        value={isItemSelected(name)}
                                    />
                                ))}
                        </DropdownMenu.Section>
                    </DropdownMenu>
                </div>
                <TextFilter
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    setFilterValue={setFilterValue}
                    showInput={showInput}
                    setShowInput={setShowInput}
                    aria-label={searchFilterPlaceholder}
                    placeholder={searchFilterPlaceholder}
                />
            </div>
            {sortedInfo.length > PAGE_SIZE && (
                <PaginationBar
                    currentPage={currentPage}
                    numTotalRows={numAnswers}
                    pageSize={PAGE_SIZE}
                    setCurrentPage={setCurrentPage}
                    disabled={false}
                />
            )}
            <div className={"oracle-flex-vertical gap-16"}>
                {displayedInfo.map((info) => (
                    <OracleCard
                        key={info.id}
                        open={openStates[info.id as number]}
                        toggleOpen={(open: boolean) => toggleOpen(info.id as number, open)}
                        info={info}
                    />
                ))}
            </div>
        </div>
    );
}
